import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { MdSearch } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa6";
import { FaRegPlusSquare } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import ChargerItem from "../../components/list/ChargerItem.js";
import ChargerDetailModal from "../../components/modals/ChargerDetailModal.js";
import ChargerEnrollModal from '../../components/modals/ChargerEnrollModal.js';
import { getCookie } from '../../util/util.js';

const CpoChargerManagement = () => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");


  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:5, idx:1}));
  },[])


// 충전소 데이터 Array<Object>
const [stationInfo, setStationInfo]=useState(null);
// 충전기 데이터 Array<Object>
const [chargerInfo, setChargerInfo]=useState(null);
// 충전기 controlhub 데이터 Array<Object>
const [chargerHubInfo, setChargerHubInfo]=useState(null);

//TODO 계정으로부터 받아올 데이터 (cpo id)

let cpo = useSelector((state) => state.cpo );

// let cpo = 'f296c394-8e67-4c83-9cff-3240c3286e8d';
useEffect(()=>{
  let cpoId = localStorage.getItem('cpoId');
  getStationList();
},[])

  // cpo가 소유한 station GET
  const getStationList = async(id)=>{  
    await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      let temp = [...res.data.Results];
      setStationInfo(temp);
    })
  }

// chargerHub 정보 API GET
const getChargerHubList = async()=>{
  let temp = [];
  for await(const it of stationInfo){
    await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
  }
  setChargerHubInfo(temp);
}

// charger 정보 API GET
const getChargerList = async()=>{
  let temp = [];
  for await(const it of stationInfo){
    await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
  }
  console.log(temp);
  setChargerInfo(temp);
}

// station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
useEffect(() => {
  if(!stationInfo)return;
  getChargerHubList();
  getChargerList();
}, [stationInfo])

const [chargerTypeStatus, setChargerTypeStatus] = useState({FAST_CHARGER:0, SLOW_CHARGER:0});
const [chargerCategoryStatus, setChargerCategoryStatus] = useState({'완전공용':0, '부분공용':0, '비공용':0});

useEffect(()=>{
  if(!chargerInfo)return;
  let chargerTypeTemp = {FAST_CHARGER:0, SLOW_CHARGER:0}; //TODO : 중속??????
  let ChargerCategoryTemp = {'완전공용':0, '부분공용':0, '비공용':0};
  console.log(chargerInfo);
  for (const chargerList of chargerInfo){
    for (const charger of chargerList.Results){
      if(charger.ChargerModel?.ChargerType) chargerTypeTemp[charger.ChargerModel.ChargerType] = chargerTypeTemp[charger.ChargerModel.ChargerType]+1;
      if(charger.CommonCategory)ChargerCategoryTemp[charger.CommonCategory] = ChargerCategoryTemp[charger.CommonCategory]+1;
    }
  }
  setChargerTypeStatus(chargerTypeTemp);
  setChargerCategoryStatus(ChargerCategoryTemp);
},[chargerInfo])



  // 검색 input ref
  const searchInputRef = useRef(null);

  // 검색 function
  const submitSearch= async(e)=>{
    e.preventDefault();
    console.log(searchInputRef.current.value);
  }

  // 상세검색창 open state
  const [detailSearchOpen, setDetailSearchOpen] = useState(false);

  // station list Ref[]
  const chargerRef = useRef([]);
  
  const clickEvent = (idx)=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    if(clickedCharger !== idx){
      setClickedCharger(idx)
    }
    else{
      setClickedCharger(null)
    } 
  }

  // 클릭한 station의 idx state
  const [clickedCharger, setClickedCharger] = useState(null);

  // 상세 페이지 Modal
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  // 클릭 시 상세 모달창 open
  useEffect(()=>{
    if(clickedCharger !== null){
      setDetailModalOpen(true);
    }
    else{
      setDetailModalOpen(false);
    }
  },[clickedCharger])

  // station data 
  const stationData = [
    {
      DisplayId:'MOT-1',
      ChargingStationName:'충전소1',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1,2]
    },
    {
      DisplayId:'MOT-2',
      ChargingStationName:'충전소2',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'미운영',
      ChargerIds:[]
    },
    {
      DisplayId:'MOT-3',
      ChargingStationName:'충전소3',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1]
    },
    {
      DisplayId:'MOT-4',
      ChargingStationName:'충전소4',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'미운영',
      ChargerIds:[1,2]
    },
    {
      DisplayId:'MOT-5',
      ChargingStationName:'충전소5',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영',
      ChargerIds:[1,2,3,4,5,6,7,8]
    },
    {
      DisplayId:'MOT-6',
      ChargingStationName:'충전소6',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1,2]
    },
    {
      DisplayId:'MOT-7',
      ChargingStationName:'충전소7',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1,2,3]
    },
  ]

  // 클릭한 station idx
  const [selected, setSelected] = useState(0);
  useEffect(()=>{
    if(clickedCharger !== null) setClickedCharger(0);
  },[selected])



  // check한 charger list
  const [checkChargerList, setCheckChargerList] = useState([]);

  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);

  useEffect(()=>{
    if(checked){
      let temp = new Array(stationData.length).fill(0).map((_, i) => i)
      setCheckChargerList(temp);
    }
    else{
      setCheckChargerList([]);
    }
  },[checked])

  const [enrollModalOn, setEnrollModalOn] = useState(false);

  return (
    <div className='cpo-charger-management-container'>
      {enrollModalOn&&<ChargerEnrollModal setOnOff={setEnrollModalOn}/>}
      <div className="cpo-charger-management-inner">
        {chargerInfo&&chargerHubInfo&&<ChargerDetailModal setEnrollModalOn={setEnrollModalOn} getChargerHubList={getChargerHubList} getChargerList={getChargerList} data={chargerInfo[selected].Results[clickedCharger]} chasData={chargerHubInfo[selected][clickedCharger]} detailModalOpen={detailModalOpen} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} lastIdx={chargerInfo[selected].Total}/>}
        <div className='cpo-charger-management-search-container'>
          <div className="cpo-charger-management-keyword-search-container">
            <input ref={searchInputRef} type="text" placeholder='키워드로 검색하기' onClick={(e)=>{e.stopPropagation(); }}/>
            <button><MdSearch className='search-icon'/></button>
          </div>
          <div className={detailSearchOpen ?'cpo-charger-management-detail-search-button open': 'cpo-charger-management-detail-search-button'} onClick={()=>{setDetailSearchOpen(!detailSearchOpen)}}>
            <p>상세검색</p>
            <FaChevronDown className={detailSearchOpen ?'open':''}/>
          </div>
          <div className={detailSearchOpen ? 'cpo-charger-management-detail-search-modal-container open':'cpo-charger-management-detail-search-modal-container'}>
            
          </div>
        </div>
        <div className="cpo-charger-management-category-outer">
          <div className='cpo-charger-management-category-container'>
            <p>충전기 타입</p>
            <div className="cpo-charger-management-category-type-container">
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>고속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.FAST_CHARGER}</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>중속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>0</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>완속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.SLOW_CHARGER}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='cpo-charger-management-category-container'>
            <p>충전기 분류</p>
            <div className="cpo-charger-management-category-class-container">
              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">완전공용</div>
                <p>{chargerCategoryStatus.완전공용}</p>
              </div>
              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">부분공용</div>
                <p>{chargerCategoryStatus.부분공용}</p>
              </div>
              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">비공용</div>
                <p>{chargerCategoryStatus.비공용}</p>
              </div>
            </div>
          </div>

          <div className={detailModalOpen ? 'cpo-charger-management-charger-list-container open':'cpo-charger-management-charger-list-container' }>
            <div className={"cpo-charger-management-charger-list-button-container " + `${detailModalOpen? "open":""}`}>
              <div onClick={()=>{setEnrollModalOn(true)}}><p>등록</p></div>
              <div className='delete'><p>삭제</p></div>
              {/* <div className='excel'><RiFileExcel2Line/><p>엑셀 다운로드</p></div> */}
            </div>
            <div className="cpo-charger-management-charger-list">
              <div className='cpo-charger-management-charger-station-list-wrapper'>
                <div className="cpo-charger-management-charger-station-list-column">
                  <p>충전소 선택</p>
                </div>
                <div className="cpo-charger-management-charger-station-list">
                  {
                    stationInfo?.map((it, idx)=>{
                      return(
                        <div key={"station-"+idx} onClick={()=>{setSelected(idx)}} className={"cpo-charger-management-charger-station-item "+`${selected==idx ? 'selected':''}`}><p>{it.ChargingStationName}</p></div>
                        // <div key={"station-"+idx} onClick={()=>{setSelected(idx)}} className={"cpo-charger-management-charger-station-item "+`${selected==idx ? 'selected':''}`}><p>{'벽산블루밍아파트B단지입주자대표회의 지하2층 113동114동사이 10번기둥'}</p></div>
                      );
                    })
                  }
                </div>
              </div>
              <div className='cpo-charger-management-charger-list-wrapper'>
                <div className="cpo-charger-management-charger-list-column">
                  <div className='cpo-charger-management-charger-list-column-item'>
                    <label className="checkbox_label">
                      <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                  </div>
                  <div className='cpo-charger-management-charger-list-column-item'><p>식별번호</p></div>
                  <div className='cpo-charger-management-charger-list-column-item'><p>모델명</p></div>
                  <div className='cpo-charger-management-charger-list-column-item'><p>충전기타입</p></div>
                  <div className='cpo-charger-management-charger-list-column-item'><p>커넥터타입</p></div>
                  <div className='cpo-charger-management-charger-list-column-item'><p>운영여부</p></div>
                  <div className='cpo-charger-management-charger-list-column-item'><p>OCPP</p></div>
                </div>
                <div className='cpo-charger-management-list-content'>
                  {
          //{"DisplaySerialNumber": "MOTP80985","modelName": "ModelB","ChargerType": "중속","ConnectorTypes": "차데모","status": false,"Protocol": "2.0.1"},
                  chargerInfo&&
                    (chargerInfo[selected].Results.map((ia,idx)=>{
                      return(
                        <ChargerItem key={'charger- '+idx}  idx={idx} chargerRef={chargerRef} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} checkChargerList={checkChargerList} setCheckChargerList={setCheckChargerList} data={ia}/>
                    )
                    }))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  )
}
export default CpoChargerManagement;