import React from 'react'

export const Alert = (props) => {
  return (
    <div className="cpo-alert-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-alert-container">
        <div className="cpo-alert-logo-container">
          <img className='nav-logo' alt=""/>
        </div>
        <div className="cpo-alert-content-container">
          <p>{props.title}</p>
          <pre>{props.contents}</pre>
        </div>
        <div className="cpo-alert-button-container">
          <div onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false); }}>취소</div>
          <div onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false); props.trigger();}}>확인</div>
        </div>
      </div>
    </div>
  )
}
export default Alert;