import { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {setCookie, getCookie} from "../../util/util.js"
import { useLocation } from 'react-router-dom';

export const AccountVerification = (props) => {
  const location = useLocation();
  const email = location.state.email;
  const resend = async()=>{
    await axios.get(`/account/api/NearCpoAuth/resend-confirmation-email?email=${email}`,
    {},{withCredentials : true})
    .then((res)=>{
      console.log(res);
    })
    .catch((err)=>{
    })
  }
  return (
    <>

      <div className='sign-up-page-container'>
        <div className="sign-up-page-wrapper">
          <title>가입 인증</title>
          <div style={{textAlign: "center", fontSize: "1rem", marginTop:"50px"}}>
            {"<"+email+">"}로 인증 메일을 전송하였습니다. 인증 후 서비스를 이용해주세요!
          </div>
          <div className='email-verification-button-container'>
            <div className='email-verification-resend-button' onClick={()=>{resend()}}><p>재전송</p></div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AccountVerification;