import React, { useRef, useState, useEffect } from 'react';
import { TbDotsVertical } from "react-icons/tb";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

export const FaqItem = (props) => {

  const[clicked, setClicked]=useState(false);
  // {
  //   "FaqPostId": 1,
  //   "Title": "회원탈퇴방법",
  //   "Category": "회원계정",
  //   "Contents": ``,
  //   "VisibilityLevel": "공통",
  //   "OwnerId": ""
  // }
  const[btnOpen, setBtnOpen] = useState(false);

  const edit = ()=>{
    props.setIsPatch(true);
    console.log(props.data.FaqPostId);
    props.setPostId(props.data.FaqPostId);
    props.setTitle(props.data.Title);
    props.setAnswer(props.data.Contents);
    props.setTabSelection({id:"",name:props.data.Category});
    props.setModalOpen(true);
  }

  return (
    <>
      {
        props.category == props.data.Category || props.category == "전체"
        ?
        <div className={"cpo-faq-item-container " +`${clicked ? "clicked":""}`} onClick={()=>{setClicked(!clicked)}}>
          <div className='cpo-faq-item-owner-container'>
            {props.data.OwnerId =="" ?
              <div className="cpo-faq-item-owner common"><p>공통</p></div>
              :
              <div className="cpo-faq-item-owner cpo"><p>회원종속</p></div>
            }
          </div>
          <div className="cpo-faq-item-category-title-container"><p>{'Q. '}</p><p>{`${props.data.Title}`}</p></div>
          {props.data.OwnerId =="" ? 
            <></>:
            <div className="cpo-faq-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
              <div className={"cpo-fap-item-function-button-list " + `${btnOpen ? "open":""}`}>
                <div>
                  <div className="cpo-fap-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); edit();}}>
                    <div><FiEdit3/></div>
                  </div>
                </div>

                <div>
                  <div className="cpo-fap-item-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
                    <div><FaRegTrashAlt/></div>
                  </div>
                </div>

              </div>
              <TbDotsVertical/>
            </div>
          }
          <div className="cpo-faq-item-category-container"><p>{props.data.Category}</p></div>
          <div className={"cpo-faq-item-category-content-container " +`${clicked ? "clicked":""}`}>
            <p>{'A. '}</p>
            <pre>
              {
                props.data.Contents
              }
            </pre>
          </div>
        </div>
        :<></>
      }
    </>
  )
}
export default FaqItem