import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';


export const SearchSelector = (props) => {
  const [value, setValue] = useState();
  useEffect(()=>{
    props.setDataLabel(value);
  },[value])
  return (
    <CreatableSelect options={props.options}
    {...props}
    className="react-select-container"
    classNamePrefix="react-select"
    formatCreateLabel={(newValue)=>`'${newValue}' 생성하기`}
    value={value}
    onChange={(newValue) => setValue(newValue)}
    />
  )
}
export default SearchSelector;