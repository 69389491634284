import React, { useRef, useState, useEffect } from 'react';
import { TbDotsVertical } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

export const CHASItem = (props) => {
  const[clicked, setClicked]=useState(false);

  const[btnOpen, setBtnOpen] = useState(false);

  useEffect(()=>{
    setClicked(false);
  },[props.clickedActionSet])
  return (
    <>
      {
        props.category == props.data.Category || props.category == "전체"
        ?
        <div className={"cpo-chas-item-container " +`${clicked ? "clicked":""}`} onClick={()=>{setClicked(!clicked)}}>
          <div className="cpo-chas-item-category-title-container"><p>{`${props.title}`}</p></div>
          {props.data.OwnerId =="" ? 
            <></>:
            <div className="cpo-chas-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
              <div className={"cpo-fap-item-function-button-list " + `${btnOpen ? "open":""}`}>
                <div>
                  <div className="cpo-fap-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
                    <div><AiFillEdit/></div>
                  </div>
                </div>

                <div>
                  <div className="cpo-fap-item-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
                    <div><MdDelete/></div>
                  </div>
                </div>

              </div>
              <TbDotsVertical/>
            </div>
          }
          <div className={"cpo-chas-action-list-container " +`${clicked ? "clicked":""}`}>
            <div className="cpo-chas-action-list">
              <div className='cpo-chas-action-list-column'><p>순서</p></div>
              <div className='cpo-chas-action-list-column'><p>action명</p></div>
              <div className='cpo-chas-action-list-column'><p>설명</p></div>
              <div className='cpo-chas-action-list-column'><p>invokeType</p></div>
              <div className='cpo-chas-action-list-column'><p>invokeKey</p></div>
              <div className='cpo-chas-action-list-column'><p>클래스명</p></div>
              {
                props.data.map((it, idx)=>{
                  return(
                    <>
                      <div className='cpo-chas-action-list-cell'><p>{it.order}</p></div>
                      <div className='cpo-chas-action-list-cell'><p>{it.chControlHubAction.name}</p></div>
                      <div className='cpo-chas-action-list-cell'><p>{it.chControlHubAction.description}</p></div>
                      <div className='cpo-chas-action-list-cell'><p>{it.chControlHubAction.invokeType}</p></div>
                      <div className='cpo-chas-action-list-cell'><p>{it.chControlHubAction.invokeKey}</p></div>
                      <div className='cpo-chas-action-list-cell'><p>{it.chControlHubAction.className}</p></div>
                      {/* <div className="cpo-chas-action-wrapper">
                      </div> */}
                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
        :<></>
      }
    </>
  )
}
export default CHASItem;