import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';

export const ChargingFeeModalChargerItem = (props) => {
  let signedToken = getCookie("signedToken");
  const [memberRate, setMemberRate] = useState(props.chargerRate.memberRate);
  const [nonMemberRate, setNonmemberRate] = useState(props.chargerRate.nonMemberRate);
  useEffect(()=>{setMemberRate(props.chargerRate.memberRate); setNonmemberRate(props.chargerRate.nonMemberRate)},[props.chargerRate])
  console.log(props.chargerRate);


  const rateInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
  }

  const requestChargerRate = async()=>{
    await axios.put(`/chargerate/api/cpouser/Chargers/${props.data.ChargerId}/ChargeRates`,
    {
      "membershipRate" : parseInt(memberRate),
      "nonMembershipRate" : parseInt(nonMemberRate),
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      props.getChargerRateList();
      props.setAlertOn(true);
    })
  }

  return (
    <div className='charging-fee-modal-charger-item-flex-wrapper'>
      <div className={"cpo-charging-fee-management-list-content-item charger-modal"} >
        <div><p>{props.stationInfo?.ChargingStation?.RegionName ? props.stationInfo?.ChargingStation?.RegionName : "-"}</p></div>
        <div><p>{props.data?.ChargingStation?.ChargingStationName ? props.data?.ChargingStation?.ChargingStationName : "-"}</p></div>
        <div><p>{props.data?.DisplaySerialNumber ? props.data?.DisplaySerialNumber : "-"}</p></div>
        <div><p style={{color:'#aaa',fontWeight:"800"}}>{props.chargerHubInfo?.Used ? 'Y':'N'}</p></div>
        <div className="cpo-charging-fee-setting-input-container charger-modal">
          <input type="text" placeholder='(단위: 원)' value={memberRate} onChange={(e)=>{ setMemberRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
        </div>
        <div className="cpo-charging-fee-setting-input-container charger-modal">
          <input type="text" placeholder='(단위: 원)' value={nonMemberRate} onChange={(e)=>{ setNonmemberRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation();}}/>
        </div>
      </div>
      <div className={'cpo-charging-fee-setting-enroll-button apply'} onClick={()=>{requestChargerRate();}}><p>적용</p></div>
    </div>
  )
}
export default ChargingFeeModalChargerItem;