import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { MdSearch } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa6";
import { FaRegPlusSquare } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import StationItem from "../../components/list/StationItem.js";
import StationDetailModal from "../../components/modals/StationDetailModal.js";
import StationEnrollModal from '../../components/modals/StationEnrollModal.js';
import { getCookie } from '../../util/util.js'; 
// 충전인프라관리 - 충전소 관리
const CpoStationManagement = () => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");  
  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:5, idx:0}));
  },[])

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);

  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  
  useEffect(()=>{
    getStationList();
  },[])
  
  // cpo가 소유한 station GET
  const getStationList = async(id)=>{  
    await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      let temp = [...res.data.Results];
      setStationInfo(temp);
    })
  }

  // chargerHub 정보 API GET
  const getChargerHubList = async()=>{
    let temp = [];
    for await(const it of stationInfo){
      await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
    }
    setChargerHubInfo(temp);
  }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    getChargerHubList();    
  }, [stationInfo])


  // 검색 input ref
  const searchInputRef = useRef(null);

  // 검색 function
  const submitSearch= async(e)=>{
    e.preventDefault();
  }

  // station list Ref[]
  const stationRef = useRef([]);
  
  // 클릭한 station의 idx state
  const [clickedStation, setClickedStation] = useState(null);

  // 상세 페이지 Modal
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  // 상세검색창 open state
  const [detailSearchOpen, setDetailSearchOpen] = useState(false);


  useEffect(()=>{
    if(clickedStation !== null){
      setDetailModalOpen(true);
    }
    else{
      setDetailModalOpen(false);
    }
  },[clickedStation])

  // check한 station list
  const [checkStationList, setCheckStationList] = useState([]);

  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    if(checked){
      let temp = new Array(stationInfo.length).fill(0).map((_, i) => i)
      setCheckStationList(temp);
    }
    else{
      setCheckStationList([]);
    }
  },[checked])

  const [enrollModalOn, setEnrollModalOn] = useState(false);
  

  const [facility, setFacility] = useState({'공공시설':0,'주차시설':0,'휴게시설':0,'관광시설':0,'상업시설':0,'챠랑정비시설':0,'기타시설':0,'공동주택시설':0,'근린생활시설':0,'교육문화시설':0});

  useEffect(()=>{
    if(!stationInfo)return;
    getFacilityClassification();
  },[stationInfo])
  const getFacilityClassification = async()=>{
    let facilityTemp = {'공공시설':0,'주차시설':0,'휴게시설':0,'관광시설':0,'상업시설':0,'챠랑정비시설':0,'기타시설':0,'공동주택시설':0,'근린생활시설':0,'교육문화시설':0};
    for await(const station of stationInfo){
      if(station.FacilityClassification) facilityTemp[station.FacilityClassification] = facilityTemp[station.FacilityClassification]+1;
    }
    console.log(facilityTemp);
    setFacility(facilityTemp);
  }
  

  return (
    <div className='cpo-station-management-container'>
      {enrollModalOn &&<StationEnrollModal setOnOff={setEnrollModalOn}/>}
      <div className="cpo-station-management-inner">
        {stationInfo&&chargerHubInfo&&<StationDetailModal setEnrollModalOn={setEnrollModalOn} getStationList={getStationList} chargerHubInfo={chargerHubInfo[clickedStation]} data={stationInfo[clickedStation]} detailModalOpen={detailModalOpen} clickedStation={clickedStation} setClickedStation={setClickedStation} lastIdx={stationInfo.length-1}/>}
        <div className='cpo-station-management-search-container'>
          <div className="cpo-station-management-keyword-search-container">
            <input ref={searchInputRef} type="text" placeholder='키워드로 검색하기' onClick={(e)=>{e.stopPropagation(); }}/>
            <button><MdSearch className='search-icon'/></button>
          </div>
          <div className={detailSearchOpen ?'cpo-station-management-detail-search-button open': 'cpo-station-management-detail-search-button'} onClick={()=>{setDetailSearchOpen(!detailSearchOpen)}}>
            <p>상세검색</p>
            <FaChevronDown className={detailSearchOpen ?'open':''}/>
          </div>
          <div className={detailSearchOpen ? 'cpo-station-management-detail-search-modal-container open':'cpo-station-management-detail-search-modal-container'}>
            
          </div>
        </div>
        <div className="cpo-station-management-ten-category-outer">
          <div className='cpo-station-management-ten-category-container'>
            <div><p className='cpo-station-management-category' onClick={()=>{console.log(stationInfo)}}>공공시설</p><p>{facility['공공시설']}</p></div>
            <div><p className='cpo-station-management-category'>주차시설</p><p>{facility["주차시설"]}</p></div>
            <div><p className='cpo-station-management-category'>휴게시설</p><p>{facility.휴게시설}</p></div>
            <div><p className='cpo-station-management-category'>관광시설</p><p>{facility.관광시설}</p></div>
            <div><p className='cpo-station-management-category'>상업시설</p><p>{facility.상업시설}</p></div>
            <div><p className='cpo-station-management-category'>챠랑정비시설</p><p>{facility.챠랑정비시설}</p></div>
            <div><p className='cpo-station-management-category'>기타시설</p><p>{facility.기타시설}</p></div>
            <div><p className='cpo-station-management-category'>공동주택시설</p><p>{facility.공동주택시설}</p></div>
            <div><p className='cpo-station-management-category'>근린생활시설</p><p>{facility.근린생활시설}</p></div>
            <div><p className='cpo-station-management-category'>교육문화시설</p><p>{facility.교육문화시설}</p></div>
          </div>
        </div>

        <div className={detailModalOpen ? 'cpo-station-management-station-list-container open':'cpo-station-management-station-list-container' }>
          <div className={"cpo-station-management-station-list-button-container " + `${detailModalOpen? "open":""}`}>
            <div onClick={()=>{setEnrollModalOn(true)}}><p>등록</p></div>
            <div className='delete'><p>삭제</p></div>
            {/* <div className='excel'><RiFileExcel2Line/><p>엑셀 다운로드</p></div> */}
          </div>
          <div className="cpo-station-management-station-list">
            <div className="cpo-station-management-station-list-column">
              <div className='cpo-station-management-station-list-column-item'></div>
              <div className='cpo-station-management-station-list-column-item'><p>권역</p></div>
              <div className='cpo-station-management-station-list-column-item'><p>충전소명</p></div>
              <div className='cpo-station-management-station-list-column-item'><p>주소</p></div>
              <div className='cpo-station-management-station-list-column-item'><p>운용대수</p></div>
              <div className='cpo-station-management-station-list-column-item'><p>충전기 총 대수</p></div>
              <div className='cpo-station-management-station-list-column-item checkbox'>
                <label className="checkbox_label">
                  <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                  <span className="checkbox_icon"></span>
                </label>
                <p>전체선택</p>
              </div>
            </div>
            <div className='cpo-station-management-list-content'>
              {
                stationInfo&&chargerHubInfo&&(stationInfo.map((it,idx)=>{
                  if(it.OpStatus == "삭제됨")return;
                  return(<StationItem key={'stationItem-'+idx} chargerHubInfo={chargerHubInfo[idx]} checkStationList={checkStationList} setCheckStationList={setCheckStationList} clickedStation={clickedStation} setClickedStation={setClickedStation} stationRef={stationRef} idx={idx}  data={it} />)
                }))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoStationManagement;