import React, { useRef, useState, useEffect } from 'react';

const StationItem = (props) => {

  // station item 클릭 이벤트 콜백 function
  const clickEvent = ()=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    if(props.clickedStation !== props.idx){
      props.setClickedStation(props.idx)
    }
    else{
      props.setClickedStation(null)
    } 
  }
  // checked 상태
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkStationList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      props.setCheckStationList([...props.checkStationList, props.idx]);
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx == -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkStationList];
        temp.splice(itemIdx,1);
        props.setCheckStationList(temp);
      }
    }
  },[checked])

  useEffect(()=>{
    let itemIdx = props.checkStationList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx == -1 && checked){
      setChecked(false);
    }
  },[props.checkStationList])


  return (
    <>
      <div className={props.clickedStation == props.idx ? 'cpo-station-management-list-content-item clicked':'cpo-station-management-list-content-item'}
      ref={(el)=>{props.stationRef.current[props.idx]=el}}
      onClick={()=>{clickEvent()}}
      >
        <div>
          <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
            <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
            <span className="checkbox_icon"></span>
          </label>
        </div>
        <div><p>{`${props.data.RegionName ? props.data.RegionName:"-"}`}</p></div>
        <div><p>{props.data.ChargingStationName}</p></div>
        <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.Address ?props.data.Address+' '+(props.data.DetailedAddress ? props.data.DetailedAddress:""):"-"}</p></div>
        <div><p style={{color:props.chargerHubInfo.filter(it=>it.used == true).length !== 0?'var(--emoovBlue)':'#ddd',fontWeight:"800"}}>{props.chargerHubInfo.filter(it=>it.used == true).length}</p></div>
        <div><p>{props.data.ChargerIds.length}</p></div>
      </div>
    </>
  )
}
export default StationItem;