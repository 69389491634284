import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { CiMoneyBill } from "react-icons/ci";
import { ImMakeGroup } from "react-icons/im";
import { FaChargingStation } from "react-icons/fa";

import { setCookie, getCookie } from '../../util/util';
import Selector2 from '../buttons/Selector2';
import ChargingFeeModalChargerItem from '../list/ChargingFeeModalChargerItem';

export const ChargingFeeSettingModal = (props) => {
  const [tabIdx, setTabIdx] = useState(0);
  let signedToken = getCookie("signedToken");


  const [membershipRate, setMembershipRate] = useState(props.defaultRate?.membershipRate);
  const [nonMembershipRate, setNnMembershipRate] = useState(props.defaultRate?.nonMembershipRate);

  const [regionMemberRate, setRegionMemberRate] = useState(props.defaultRate?.membershipRate);
  const [regionNonMemberRate, setRegionNonMemberRate] = useState(props.defaultRate?.nonMembershipRate);
  const [stationMemberRate, setStationMemberRate] = useState(props.defaultRate?.membershipRate);
  const [stationNonMemberRate, setStationNonMemberRate] = useState(props.defaultRate?.nonMembershipRate);
  
  const [chargerRateList, setChargerRateList] = useState(props.chargerRateList);

  useEffect(()=>{setChargerRateList(props.chargerRateList)},[props.chargerRateList])

  let postDefaultRate = async()=>{
    await axios.post(`/chargerate/api/cpouser/DefaultRate`,
    {
      "membershipRate" : parseInt(membershipRate),
      "nonMembershipRate" : parseInt(nonMembershipRate),
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      props.setDefaultRate(
        {
          "membershipRate" : membershipRate,
          "nonMembershipRate" : nonMembershipRate,
        }
      );
    })
  }

  const rateInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
  }


  const regionList = [
    {name: 'A(서울, 인천, 경기)'},
    {name: 'B(충청, 전라)'},
    {name: 'C(강원, 경상)'},
    {name: 'D(제주)'},
    // {name: '미분류'},
  ];

  const [Region, setRegion] = useState('');
  const [RegionActive, setRegionActive] = useState(null);
  const [Station, setStation] = useState('');
  const [StationActive, setStationActive] = useState(null);
  const activeReset = ()=>{
    setRegionActive(false);
    setStationActive(false);
  }
  

  const requestStationRate = async()=>{
    await axios.put(`/chargerate/api/cpouser/ChargingStations/${Station.ChargingStationId}/ChargeRates`,
    {
      "membershipRate" : parseInt(stationMemberRate),
      "nonMembershipRate" : parseInt(stationNonMemberRate),
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      props.getChargerRateList();
      setAlertOn(true);
    })
  }

  const [alertOn, setAlertOn] = useState(false);

  useEffect(()=>{
    if(alertOn){
      setTimeout(() => {
        setAlertOn(false)
      }, 1300);
    }
  },[alertOn])

  return (
    <div className="cpo-charging-fee-setting-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charging-fee-setting-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        {alertOn && <div className='cpo-charging-fee-setting-modal-alert'><p>정상적으로 적용이 완료되었습니다.</p></div>}
        <div className="cpo-charging-fee-setting-modal-header">
          <title onClick={()=>{console.log(props.chargerRateList);}}>충전 요금 설정</title>
        </div>
        {
          tabIdx == 0 ?
          <>
            <div className='cpo-charging-fee-setting-target-select-button-container'>
              <div className='cpo-charging-fee-setting-target-select-button' onClick={()=>{setTabIdx(1)}}>
                <CiMoneyBill/>
                <p><strong>기본</strong> 요금 설정</p>
              </div>
              <div className='cpo-charging-fee-setting-target-select-button' onClick={()=>{setTabIdx(2)}}>
                <FaChargingStation/>
                <p><strong>충전기 별</strong> 요금 설정</p>
              </div>
            </div>
            <div></div>
          </>
          :
          tabIdx == 1 ?
          <>
            <div className='cpo-charging-fee-setting-default-title'>
              <CiMoneyBill/><p>기본 요금 설정</p>
            </div>
            <div className='cpo-charging-fee-setting-default-input-wrapper'>
              <div>
                <p>멤버쉽 요금</p>
                <div className="cpo-charging-fee-setting-input-container">
                  <input type="text" placeholder='(단위: 원)' value={membershipRate} onChange={(e)=>{ setMembershipRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
              <div>
                <p>멤버쉽 요금</p>
                <div className="cpo-charging-fee-setting-input-container">
                  <input type="text" placeholder='(단위: 원)' value={nonMembershipRate} onChange={(e)=>{ setNnMembershipRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
            </div>
            <div className='cpo-charging-fee-tab-controller'>
              <div className={'cpo-charging-fee-setting-back-button '} onClick={()=>{setTabIdx(0)}}><p>이전</p></div>
              <div className={'cpo-charging-fee-setting-enroll-button ' + `${membershipRate && nonMembershipRate ? "": "none" }`} onClick={()=>{postDefaultRate(); props.setOnOff(false)}}><p>등록</p></div>
            </div>
          </>
          :
          <>
            <div className='cpo-charging-fee-setting-default-title'>
              <FaChargingStation/><p>충전기 별 요금 설정</p>
            </div>
            <div className='cpo-charging-fee-setting-modal-charger-container'>
              <div>
                <title>권역 일괄 설정</title>

                <div className="cpo-charging-fee-setting-modal-function-container">

                  <div className="cpo-charging-fee-setting-modal-function-column1">
                    <Selector2 name={"name"} dataList={regionList} select={Region} setSelect={setRegion} active={RegionActive} setActive={setRegionActive} activeReset={activeReset}/>
                  </div>

                  <div className='cpo-charging-fee-setting-modal-function-column2'>
                    <p>멤버쉽</p>
                    <div className="cpo-charging-fee-setting-input-container">
                      <input type="text" placeholder='(단위: 원)' value={regionMemberRate} onChange={(e)=>{ setRegionMemberRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>

                  <div className='cpo-charging-fee-setting-modal-function-column3'>
                    <p>비멤버쉽</p>
                    <div className="cpo-charging-fee-setting-input-container region">
                      <input type="text" placeholder='(단위: 원)' value={regionNonMemberRate} onChange={(e)=>{ setRegionNonMemberRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>

                  <div className='cpo-charging-fee-setting-apply-button-wrapper'>
                    <div className={'cpo-charging-fee-setting-enroll-button apply ' + `${Region && membershipRate && nonMembershipRate ? "": "none" }`} onClick={()=>{props.setOnOff(false)}}><p>적용</p></div>
                  </div>
                </div>
                <title>충전소 일괄 설정</title>

                <div className="cpo-charging-fee-setting-modal-function-container">

                  <div className="cpo-charging-fee-setting-modal-function-column1">
                    <Selector2 name={"ChargingStationName"} dataList={props.stationInfo} select={Station} setSelect={setStation} active={StationActive} setActive={setStationActive} activeReset={activeReset}/>
                  </div>

                  <div className='cpo-charging-fee-setting-modal-function-column2'>
                    <p>멤버쉽</p>
                    <div className="cpo-charging-fee-setting-input-container">
                      <input type="text" placeholder='(단위: 원)' value={stationMemberRate} onChange={(e)=>{ setStationMemberRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>

                  <div className='cpo-charging-fee-setting-modal-function-column3'>
                    <p>비멤버쉽</p>
                    <div className="cpo-charging-fee-setting-input-container region">
                      <input type="text" placeholder='(단위: 원)' value={stationNonMemberRate} onChange={(e)=>{ setStationNonMemberRate(e.target.value)}} onInput={(e)=>{rateInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>

                  <div className='cpo-charging-fee-setting-apply-button-wrapper'>
                    <div className={'cpo-charging-fee-setting-enroll-button apply ' + `${Station && membershipRate && nonMembershipRate ? "": "none" }`} onClick={()=>{requestStationRate()}}><p>적용</p></div>
                  </div>
                </div>
                
              </div>
              <div>
                <div className='cpo-charging-fee-setting-modal-charger-column'>
                  <div className='cpo-charging-fee-setting-modal-charger-column-item'><p>권역</p></div>
                  <div className='cpo-charging-fee-setting-modal-charger-column-item'><p>충전소명</p></div>
                  <div className='cpo-charging-fee-setting-modal-charger-column-item'><p>식별번호</p></div>
                  <div className='cpo-charging-fee-setting-modal-charger-column-item'><p>운영여부</p></div>
                  <div className='cpo-charging-fee-setting-modal-charger-column-item'><p>멤버쉽 단가</p></div>
                  <div className='cpo-charging-fee-setting-modal-charger-column-item'><p>비멤버쉽 단가</p></div>
                  <div className='cpo-charging-fee-setting-modal-charger-column-item'></div>
                </div>
                {
          //{"DisplaySerialNumber": "MOTP80985","modelName": "ModelB","ChargerType": "중속","ConnectorTypes": "차데모","status": false,"Protocol": "2.0.1"},
                  props.chargerInfo&&
                    (props.chargerInfo[props.stationInfo.indexOf(Station)]?.Results.map((ia,idx)=>{
                      return(
                        <ChargingFeeModalChargerItem setAlertOn={setAlertOn} getChargerRateList={props.getChargerRateList} data={ia} chargerRate={chargerRateList.filter(it=> it.chargerId == ia.ChargerId)[0]} stationInfo={Station} chargerHubInfo={props.chargerHubInfo[props.stationInfo.indexOf(Station)]}/>
                    )
                    }))
                  }
              </div>
            </div>
            <div className='cpo-charging-fee-tab-controller'>
              <div className={'cpo-charging-fee-setting-back-button '} onClick={()=>{setTabIdx(0)}}><p>이전</p></div>
              <div className={'cpo-charging-fee-setting-enroll-button ' + `${membershipRate && nonMembershipRate ? "": "none" }`} onClick={()=>{postDefaultRate(); props.setOnOff(false)}}><p>등록</p></div>
            </div>
          </>
        }
      </div>
    </div>
  )
}
export default ChargingFeeSettingModal;

