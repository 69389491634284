import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate } from "react-router-dom";
import BreakdownItem from '../../components/list/BreakdownItem.js';

export const CpoBreakdown = () => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:6, idx:3}));
  },[])

  let navigator = useNavigate();
  const[clicked, setClicked]=useState(false);
  const [BreakdownList, setBreakdownList] = useState([]);
  const getBreakdownList = async()=>{
    //TODO cpo id를 계정으로 부터 받아서 쿼리에 넣을 예정
    await axios.get(`/cs/api/Breakdown?cpoMemberId=${'kassid'}`,{
    })
    .then((res)=>{
      setBreakdownList([...res.data]);
    })
    .catch((err)=>{
      
    })
    
  }
  useEffect(()=>{
    getBreakdownList();
  },[])
  return (
    <div className='cpo-customer-service-container'>
      <div className="cpo-customer-service-inner notice">
        <div className="cpo-notice-container">
          <div className={"cpo-notice-header "}>
            <div className='cpo-faq-tab-container'>
              <div className={"faq-tab-button selected"}><p>고장신고</p></div>
              <div className={'faq-tab-bar '+'tab0'}></div>
            </div>
          </div>
          <div className="cpo-notice-list-container">
            {
              BreakdownList.map((it, idx)=>{
                return(<BreakdownItem key={idx} data={it} getBreakdownList={getBreakdownList}/>);
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoBreakdown;