import React,{useState, useEffect} from 'react'

import { RiCustomerServiceFill, RiTeamFill, RiBattery2ChargeFill, RiTimerFlashLine } from "react-icons/ri";
import { FaChargingStation } from "react-icons/fa";
import { MdSpaceDashboard, MdManageAccounts } from "react-icons/md";

import { TbAdjustmentsBolt, TbSettingsBolt, TbReportAnalytics,TbReportMoney } from "react-icons/tb";
import { FaChevronDown } from "react-icons/fa";

const NavButton = (props) => {
  // Icon return 함수
  const icon = (idx)=>{
    switch (idx) {
      case 0:
        return <MdSpaceDashboard/>;
      
      case 1:
        return <RiTimerFlashLine/>;
    
      case 2:
        return <FaChargingStation/>;

      case 3:
        return <TbReportAnalytics/>;
      
      case 4:
        return <TbReportMoney/>;
      
      case 5:
        return <TbAdjustmentsBolt/>;
      
      case 6:
        return <RiCustomerServiceFill/>;
      
      case 7:
        return <TbSettingsBolt/>;

      case 8:
        return <MdManageAccounts/>;
      
      case 9:
        return <RiTeamFill/>;
      
      default:
        return <RiBattery2ChargeFill/>;
    }
  }



  return (
    <div className={props.selectedIdx === props.id ? "active nav-button-inner " : "nav-button-inner "}>
      <div onClick={props.onClick} className={"nav-button"}>
        {icon(props.data.id)}
        <p>{props.data.name}</p>
        { // subNav가 있는가?
          props.data.items ? 
            <FaChevronDown className={props.selectedIdx === props.id ? 'nav-chevron active':'nav-chevron'}/>
          :null
        }
      </div>
    </div>
  )
}

export default NavButton;