import React from 'react'
import { FaCheck, FaCaretDown, FaUserPlus } from "react-icons/fa";


const Selector = (props) => {
  return (
    <div className="selector-wrapper">
      <div className={'selector '+`${props.active ? 'active ':''}`+`${props.disabled ? 'disabled ':''}`} onClick={(e)=>{e.stopPropagation(); 
        if(props.activeReset)props.activeReset();
        props.setActive(!props.active);
      }}>
        <p className={props.select==null ?'none':""}>{props.select==null ? "-" : props.select?.name}</p>
      </div>
      <FaCaretDown className={'selector-down-icon ' + `${props.disabled ? 'disabled':''}`} onClick={(e)=>{e.stopPropagation();
        if(props.activeReset)props.activeReset();
        props.setActive(!props.active);
      }}/>
      <div className={'selector-item-list ' + `${props.active ? '':'none '}` +`${props.grid ? "grid ":""}`}>
        {
          props.dataList?.map((it, idx)=>{
            return(
            <div key={it.name} className={'selector-item ' + `${props.active ? '':'none'}`} onClick={()=>{props.setSelect(it); props.setActive(false)}}>
              <p>{it.name}</p>
              {
                props.select?.name == it.name?
                <FaCheck className='selector-check-icon'/>
                :null
              }
            </div>
            )
          })
        }
        {
          props.append ?
          <div className={'selector-item append ' + `${props.active ? '':'none '}`} onClick={()=>{props.setActive(false)}}>
            <FaUserPlus/>
            <p>추가 등록하기</p>
          </div>
          :null
        }
      </div>
  </div>
  )
}
export default Selector;