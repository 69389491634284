import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum, setDetailData} from '../../store.js'

import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps'
import MarkerShape from '../marker/MarkerShape.js';

const StationDetailTab2 = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let detail = useSelector((state)=>state.detail);
  let dispatch = useDispatch();
  // https://naveropenapi.apigw.ntruss.com/map-geocode/v2/geocode?query={주소}
  const navermaps = useNavermaps();
  let [map, setMap] = useState();
  // TODO props로 받아온 데이터로 초기화 예정
  const [address, setAddress] = useState('세종특별자치시  군청로  95 세종테크노파크');
  const [reg, setReg] = useState("");
  const [pos, setPos] =useState(new navermaps.LatLng(36.59331009533951, 127.29307046421141));




  const[DetailedAddress, setDetailedAddress]=useState("");

  function makeAddress(item) {
    if (!item) {
      return;
    }

    var name = item.name, region = item.region, land = item.land, isRoadAddress = name === 'roadaddr';
    var sido = '', sigugun = '', dongmyun = '', ri = '', rest = '';

    if (hasArea(region.area1)) {
      sido = region.area1.name;
    }
    if (hasArea(region.area2)) {
      sigugun = region.area2.name;
    }
    if (hasArea(region.area3)) {
      dongmyun = region.area3.name;
    }
    if (hasArea(region.area4)) {
      ri = region.area4.name;
    }
    if (land) {
      if (hasData(land.number1)) {
        if (hasData(land.type) && land.type === '2') {
          rest += '산';
        }
        rest += land.number1;
        if (hasData(land.number2)) {
          rest += ('-' + land.number2);
        }
      }
      if (isRoadAddress === true) {
        if (checkLastString(dongmyun, '면')) {
          ri = land.name;
        } else {
          dongmyun = land.name;
          ri = '';
        }
        if (hasAddition(land.addition0)) {
          rest += ' ' + land.addition0.value;
        }
      }
    }
    return [sido, sigugun, dongmyun, ri, rest].join(' ');
  }
  function searchAddressToCoordinate(address) {
    if(address=='')return;
    navermaps.Service.geocode({
        query: address
    }, function(status, response) {
        if (status === navermaps.Service.Status.ERROR) {
          return alert('Something Wrong!');
        }
        var htmlAddresses = [],
          item = response.v2.addresses[0],
          point = new navermaps.LatLng(item.y, item.x);
        if (item.roadAddress) {
          htmlAddresses.push(item.roadAddress);
        }
        setAddress(htmlAddresses);
        setPos(point);
        map.setCenter(point);
    });
  }
  function hasArea(area) {
    return !!(area && area.name && area.name !== '');
  }

  function hasData(data) {
    return !!(data && data !== '');
  }

  function checkLastString (word, lastString) {
    return new RegExp(lastString + '$').test(word);
  }

  function hasAddition (addition) {
    return !!(addition && addition.value);
  }

  const changeCenter = (e) =>{
    setPos(new navermaps.LatLng(e.y, e.x));
    navermaps.Service.reverseGeocode({
      coords: new navermaps.LatLng(e.y, e.x),
        orders: [
            navermaps.Service.OrderType.ROAD_ADDR
        ].join(',')
    }, function(status, response) {
        if (status !== navermaps.Service.Status.OK) {
            return alert('Something wrong!');
        }
        let res = response.v2 // 검색 결과의 컨테이너
        //     address = res.address; // 검색 결과로 만든 주소
        // console.log(e.y, e.x);
        // console.log(address)
        var items = response.v2.results,
            address = '',
            region= '',
            htmlAddresses = [];

        let item = items[0];
        address = makeAddress(item) || '';
        region = item?.region.area1.alias;
        htmlAddresses.push(address);
        if(htmlAddresses == '') return;
        setAddress(htmlAddresses);
        setReg(regionConvert(region));
    });
  }
  const regionConvert = (region)=>{
    if(region == '경기' || region =='서울'||region =='인천'){
      return 'A(서울, 인천, 경기)';
    }
    else if(region =='충남' || region =='충북' ||region == '세종'||region =='광주'||region =='전남'||region =='전북'||region =='대전'){
      return 'B(충청, 전라)';
    }
    else if(region =='강원'||region =='경북'||region =='경남'||region =='대구'||region =='부산'||region =='울산'){  
      return 'C(강원, 경상)';
    }
    else if(region =='제주'){
      return 'D(제주)';
    }
    else{
      return '';
    }
  }

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab2');
    if(temp){
      temp = JSON.parse(temp);
      setAddress(JSON.parse(temp['address']));
      setReg(JSON.parse(temp['reg']));
      setPos(JSON.parse(temp['pos']));
      setDetailedAddress(JSON.parse(temp['DetailedAddress']));
    }
  },[])
  
  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab2');
    if(!temp){
      if(props.data?.Address)setAddress(props.data.Address);
      if(props.data?.RegionName)setReg(props.data.RegionName);
      if(props.data?.Latitude && props.data.Longitude)setPos(new navermaps.LatLng(props.data.Latitude, props.data.Longitude));
      if(props.data?.DetailedAddress)setDetailedAddress(props.data.DetailedAddress);
    }
  },[])

  useEffect(()=>{
    if(!props.open)return;
    if(address == '세종특별자치시  군청로  95 세종테크노파크' && reg =='' && JSON.stringify(pos)==JSON.stringify(new navermaps.LatLng(36.59331009533951, 127.29307046421141)) && DetailedAddress =='')return;
    if(address == props.data.Address && reg ==props.data.RegionName && JSON.stringify(pos)==JSON.stringify(new navermaps.LatLng(props.data.Latitude, props.data.Longitude)) && DetailedAddress ==props.data.DetailedAddress)return;
    localStorage.setItem(`${props.name}`+'_StationDetailTab2', JSON.stringify( {address:JSON.stringify(address), reg:JSON.stringify(reg), pos:JSON.stringify(pos), DetailedAddress:JSON.stringify(DetailedAddress)}))
    props.setSaveBtnStatus(true);
  },[address, reg, pos, DetailedAddress])

  return (
    <div className='station-detail-tab tab2'>
      <div className='station-detail-location-left-container'>
        <div>
          <div>
            <p className='station-detail-input-title'>주소</p>
            <div className="station-detail-info-input-container mapTab">
              <input type="text" placeholder='' onChange={(e)=>{setAddress(e.target.value)}} value={address} onClick={(e)=>{e.stopPropagation(); }}/> 
            </div>
          </div>
          <div>
            <p className='station-detail-input-title'>상세주소</p>
            <div className="station-detail-info-input-container mapTab">
              <input type="text" placeholder='' value={DetailedAddress} onChange={(e)=>{setDetailedAddress(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/> 
            </div>
          </div>
          <div>
            <div className='station-detail-location-convert-button-container'>
              <div className='station-detail-location-convert-button' onClick={()=>{searchAddressToCoordinate(address)}}>
                <p>좌표 변환</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='station-detail-info-input-wrapper mapTab2'>
            <p>권역</p>
            <div className="station-detail-info-input-container mapTab2">
              <input type="text" placeholder='' onClick={(e)=>{e.stopPropagation(); }} value={reg} readOnly/>
            </div>
          </div>
          <div className='station-detail-info-input-wrapper mapTab2'>
            <p>위도</p>
            <div className="station-detail-info-input-container mapTab2">
              <input type="text" placeholder='' value={pos.y} readOnly/>
            </div>
          </div>
          <div className='station-detail-info-input-wrapper mapTab2'>
            <p>경도</p>
            <div className="station-detail-info-input-container mapTab2">
              <input type="text" placeholder='' value={pos.x} readOnly/>
            </div>
          </div>
        </div>

        





      </div>
      <div className='station-detail-location-right-container'>
      {/* <div className='station-detail-location-map-header'>
        <p onClick={()=>{}}>충전소 세부 위치</p>
      </div> */}
        <MapDiv className='map'
          ncpclientid='f88olicqny'
          error={<p>Maps Load Error</p>}
          loading={<p>Maps Loading...</p>}
        >
          <NaverMap
            ref={setMap}
            defaultCenter={pos}
            defaultZoom={17}
            disableKineticPan={false}
            scaleControl={true}
            onCenterChanged={(e)=>{changeCenter(e)}}
            onBoundsChanged={(e)=>{}}
            minZoom={9}
            maxZoom={21}
          >
            <Marker position={pos}
            icon= {{content: MarkerShape(),size: new navermaps.Size(28, 28),anchor: new navermaps.Point(19, 58),}}
            />
          </NaverMap>
        </MapDiv>
      </div>
    </div>
  )
}
export default StationDetailTab2;