import { configureStore, createSlice } from '@reduxjs/toolkit'

// 상태관리 with Redux
let page = createSlice({
  name : 'page',
  initialState : {'num':{'id':-1}},
  reducers : {
    setNum(state, action){
      state.num = action.payload
    },
  }
})

let cpo = createSlice({
  name : 'cpo',
  initialState : {'id':""},
  reducers : {
    setCpoId(state, action){
      state.id = action.payload;
    },
  }
});

export default configureStore({
  reducer: {
    page : page.reducer,
    cpo : cpo.reducer
  }
}) 

export let { setNum } = page.actions
export let cpoActions = cpo.actions