import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import {setNum} from '../../store.js'
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { getCookie } from '../../util/util.js';
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import Selector2 from '../../components/buttons/Selector2.js';

const CpoFastEnroll = (props) => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  useEffect(()=>{
    dispatch(setNum({id:1, idx:0}));
  },[])

  const stationNameInputRef = useRef(null);
  const stationIdInputRef = useRef(null);
  
  const chargerIdInputRef = useRef(null);
  const chargerOCCPInputRef = useRef(null);


  const [category, setCategory] = useState(null);

  const [nameContent, setNameContent] = useState();
  const [idContent, setIdContent] = useState();
  
  const [chargerIdContent, setChargerIdContent] = useState();
  
  const [chargerOCCPContent, setChargerOCCPContent] = useState();
  
  const [memberRate, setMemberRate] = useState(null);
  const [nonMemberRate, setNonMemberRate] = useState(null);
  // 선택자 클릭 여부
  const [occpActive, setOccpActive] = useState('');
  const [occpSelect, setOccpSelect] = useState('none');
  
  const [stationActive, setStationActive] = useState('');
  const [stationSelect, setStationSelect] = useState('none');

  const activeReset = ()=>{
    setStationActive(false);
    setOccpActive(false);
  }

  

  //  validation
  const [nameContentValidation, setNameContentValidation] = useState(null);
  const [stationValidation, setStationValidation] = useState(null);
  const [nameContentDuplication, setNameContentDuplication] = useState(null);
  const [chargerIdContentValidation, setChargerIdContentValidation] = useState(null);
  const [chargerOCCPContentValidation, setChargerOCCPContentValidation] = useState(null);



  const [buttonTab1, setButtonTab1] =useState(false);

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);

  useEffect(()=>{
    getStationList();
    getDefaultRate();
  },[])
  
  // cpo가 소유한 station GET
  const getStationList = async(id)=>{  
    await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      let temp = [...res.data.Results];
      setStationInfo(temp);
    })
  }



  const stationNameDuplicationCheck = async()=>{
    await axios.get(`/infra/api/cpouser/v1/ChargingStations/isExist?codeName=${nameContent}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      if(!res.data){
        setNameContentDuplication(true);
      }
      else{
        setNameContentDuplication("disable");
      }
    })
  }

  const inputValidation = ()=>{
    if(category == 1){
      if(!nameContent || nameContentDuplication == "disable" || !nameContentDuplication){setNameContentValidation("invalid");}else{setNameContentValidation("");}
    }
    else{
      if(stationSelect == 'none'){setStationValidation("invalid")}else{setStationValidation("")}
    }
    if(!chargerIdContent){setChargerIdContentValidation("invalid");}else{setChargerIdContentValidation("");}
    if(!chargerOCCPContent){setChargerOCCPContentValidation("invalid");}else{setChargerOCCPContentValidation("");}
    if( (category == 1 ?nameContentDuplication:stationSelect) && chargerIdContent && chargerOCCPContent) return true;
    return false;
  }

  const enroll = async()=>{

    console.log(
      nameContent,
      chargerIdContent,
      chargerOCCPContent
    );
    if(!inputValidation()){return;}

    await axios.post('/infra/api/cpouser/v1/ChargingStations',{
      "chargingStationName": nameContent,
    },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      axios.post('/infra/api/cpouser/v1/Chargers',{
        "chargingStationId": res.data.ChargingStationId,
        "protocol": chargerOCCPContent == "OCPP 1.6" ? "OCPP_1_6" : "OCPP_2_0_1",
        "chargePointSerialNumber": chargerIdContent
      },{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        console.log(res);
        setChargerId(res.data.ChargerId);
      })
      .catch((err)=>{
        console.log(err);
      })

    })
    .catch((err)=>{
      console.log(err)
    })
  }


  const enroll2 = async()=>{

    console.log(
      stationSelect,
      chargerIdContent,
      chargerOCCPContent
    );
    if(!inputValidation()){return;}


    axios.post('/infra/api/cpouser/v1/Chargers',{
        "chargingStationId": stationSelect.ChargingStationId,
        "protocol": chargerOCCPContent == "OCPP 1.6" ? "OCPP_1_6" : "OCPP_2_0_1",
        "chargePointSerialNumber": chargerIdContent
      },{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        console.log(res);
        setChargerId(res.data.ChargerId);
      })
      .catch((err)=>{
        console.log(err);
      })

  }


  const [tabIdx, setTabIdx] = useState(0);
  const [chargerId, setChargerId] = useState(null);

  useEffect(()=>{
    if(!chargerId)return;
    setTimeout(() => {
      requestChargerRate(chargerId);
    }, 1000);
  },[chargerId])

  const[defaultRate, setDefaultRate] = useState({"membershipRate": null,"nonMembershipRate": null});

  
  // 기본 설정 단가 get
  let getDefaultRate = async()=>{
    await axios.get(`/chargerate/api/cpouser/DefaultRate`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setDefaultRate(res.data);
      setMemberRate(res.data.membershipRate);
      setNonMemberRate(res.data.nonMembershipRate);
    })
  }



  const numberInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
  }


  const requestChargerRate = async(chargerId)=>{
    await axios.put(`/chargerate/api/cpouser/Chargers/${chargerId}/ChargeRates`,
    {
      "membershipRate" : parseInt(memberRate),
      "nonMembershipRate" : parseInt(nonMemberRate),
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setTabIdx(3);
    })
  }


  return (
    <div className='cpo-fast-enroll-container'>
        <div className='cpo-fast-enroll-grid-container'>
            <div className='cpo-fast-enroll-front'>

              <img className='fast-enroll-background' alt="" />
              <img className='fast-enroll-logo'/>

            </div>
            <div className='fast-enroll-back-wrapper'>
              {
                tabIdx == 0 ?
                <div className={'cpo-fast-enroll-button '+`${category==null?"none":""}`} onClick={()=>{
                  setTabIdx(1);
                  }}>
                  <p>다음</p>
                  <FaChevronRight/>
                </div>
                :
                tabIdx == 1 ?
                <div className={'cpo-fast-enroll-button ' + `${buttonTab1 ? "none":""}`} onClick={()=>{
                  if(!inputValidation()){return;}
                  setTabIdx(2);
                  }}>
                  <p>다음</p>
                  <FaChevronRight/>
                </div>
                :
                tabIdx == 2 ?
                <div className='cpo-fast-enroll-button' onClick={()=>{
                  if(category == 1){ enroll();}
                  else{enroll2();}
                }}>
                  <p>다음</p>
                  <FaChevronRight/>
                </div>
                :
                <></>
              }
              {
                tabIdx == 0 ?
                <></>
                :
                tabIdx == 1 ?
                <div className='cpo-fast-enroll-button left' onClick={()=>{
                  setTabIdx(0);
                  }}>
                  <FaChevronLeft/>
                  <p>이전</p>
                </div>
                :
                tabIdx == 2 ?
                <div className='cpo-fast-enroll-button left' onClick={()=>{
                  setTabIdx(1);
                }}>
                  <FaChevronLeft/>
                  <p>이전</p>
                </div>
                :
                <></>
              }
              {
                tabIdx !== 0 ?
                <div className='cpo-fast-enroll-step-container'>
                  <div className={'cpo-fast-enroll-step '+`${tabIdx == 1 ?"current":""}`}>1</div>
                  <p className={tabIdx == 1 ? 'current':''}>충전소 등록</p>
                  <FaChevronRight className={tabIdx == 1 ? 'current':''}/>
                  <div className={'cpo-fast-enroll-step '+`${tabIdx == 2 ?"current":""}`}>2</div>
                  <p className={tabIdx == 2 ? 'current':''}>요금 설정</p>
                  <FaChevronRight className={tabIdx == 2 ? 'current':''}/>
                  <div className={'cpo-fast-enroll-step '+`${tabIdx == 3 ?"current":""}`}>3</div>
                  <p className={tabIdx == 3 ? 'current':''}>빠른 등록 완료!</p>
                </div>
                :<></>
              }
              {
              tabIdx == 0 ?
              <div className='cpo-fast-enroll-back initial'>
                <div className='cpo-fast-enroll-back-initial-title'>
                  <p>원하시는</p><p>유형을 선택</p><p>해주세요</p>  
                </div>
                <div className={'cpo-fast-enroll-category-button '+`${category == 0 ? "selected":""}`} onClick={()=>{setCategory(0)}}>
                  <div className='cpo-fast-enroll-category-select-outer'>
                    <div className='cpo-fast-enroll-category-select-inner'></div>
                  </div>
                  <div className='cpo-fast-enroll-category-content-container'>
                    <p>기존 등록</p>
                    <p>이미 충전소와 충전기가 있어요</p>
                  </div>
                </div>
                <div className={'cpo-fast-enroll-category-button '+`${category == 1 ? "selected":""}`} onClick={()=>{setCategory(1)}}>
                  <div className='cpo-fast-enroll-category-select-outer'>
                    <div className='cpo-fast-enroll-category-select-inner'></div>
                  </div>
                  <div className='cpo-fast-enroll-category-content-container'>
                    <p>신규 등록</p>
                    <p>새로운 충전소와 충전기를 등록하고 싶어요</p>
                  </div>
                </div>
              </div>
              :
              tabIdx == 1 ?
              <div className='cpo-fast-enroll-input-list-container'>  
                <p>충전소 명</p>
                {
                  category == 1 ?
                  <div className="fast-enroll-input-container name">
                    <input ref={stationNameInputRef} type="text" placeholder='충전소 명을 입력해주세요' value={nameContent} onChange={(e)=>{ setNameContent(e.target.value); setNameContentDuplication(null);}} onClick={(e)=>{e.stopPropagation(); }}/>
                    <div className={`fast-enroll-station-name-validation ` + `${nameContent==""||!nameContent?"disable":""}`} onClick={()=>{stationNameDuplicationCheck()}}><p>중복확인</p></div>
                    {
                      nameContentDuplication == "disable" ? 
                        <p className={'fast-enroll-input-validation-msg alert'}>중복된 충전소명 입니다.</p>
                      :
                        nameContentDuplication ? 
                          <p className={'fast-enroll-input-validation-msg able'}>사용가능한 충전소명 입니다.</p>
                        :
                          nameContent == "" || nameContent == null ?
                            nameContentValidation == "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>충전소명을 입력해주세요.</p>:<></>
                          :
                          <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
                    }
                  </div>
                  :
                  <div className="fast-enroll-input-container">
                    <Selector2 name={'ChargingStationName'} dataList={stationInfo} select={stationSelect} setSelect={setStationSelect} active={stationActive} setActive={setStationActive} activeReset={activeReset}/>
                    {
                    stationValidation == "invalid" ?
                    <p className={'fast-enroll-input-validation-msg alert'}>충전소를 선택해주세요.</p>
                    :
                    <></>
                  }
                  </div>
                }
              
                <p>충전기 하드웨어 일련번호</p> 
                <div className="fast-enroll-input-container">
                  <input ref={chargerIdInputRef} type="text" placeholder='일련 번호를 입력해주세요' value={chargerIdContent} onChange={(e)=>{ setChargerIdContent(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  {
                    chargerIdContentValidation == "invalid" ?
                    <p className={'fast-enroll-input-validation-msg alert'}>충전기 하드웨어 일련번호를 입력해주세요.</p>
                    :
                    <></>
                  }
                </div>
                <p>OCPP</p>
                <div className="fast-enroll-input-container occp">
                  <div className={'fast-enroll-selector '+occpActive} onClick={(e)=>{e.stopPropagation(); 
                    if(occpSelect == ''){setOccpSelect("none")}else{setOccpSelect("")} 
                    if(occpActive == ''){setOccpActive("active")}else{setOccpActive("")} 
                  }}>
                    <p className={chargerOCCPContent==null ?'none':""}>{chargerOCCPContent==null ? "프로토콜 선택하기" : chargerOCCPContent}</p>
                  </div>
                  <FaCaretDown className='cpo-occp-down-icon' onClick={(e)=>{e.stopPropagation(); 
                    if(occpSelect == ''){setOccpSelect("none")}else{setOccpSelect("")} 
                    if(occpActive == ''){setOccpActive("active")}else{setOccpActive("")} 
                  }}/>
                  <div className={'fast-enroll-selector-item-list ' + occpSelect}>
                    <div className={'fast-enroll-selector-item ' + occpSelect} onClick={()=>{setChargerOCCPContent("OCPP 1.6"); setOccpSelect('none');}}>
                      <p>OCPP 1.6</p>
                      {
                        chargerOCCPContent == "OCPP 1.6"?
                        <FaCheck className='cpo-occp-check-icon'/>
                        :null
                      }
                    </div>
                    <div className={'fast-enroll-selector-item ' + occpSelect} onClick={()=>{setChargerOCCPContent("OCPP 2.0.1"); setOccpSelect('none');}}>
                      <p>OCPP 2.0.1</p>
                      {
                        chargerOCCPContent == "OCPP 2.0.1"?
                        <FaCheck className='cpo-occp-check-icon'/>
                        :null
                      }
                    </div>
                  </div>
                  {
                    chargerOCCPContentValidation == "invalid" ? 
                    <p className={'fast-enroll-input-validation-msg alert'}>OCPP 프로토콜을 선택해주세요.</p>
                    :
                    <></>
                  }
                </div>
              </div>
              :
              tabIdx == 2 ?
              <div className='cpo-fast-enroll-input-list-container'>  
                <form onSubmit={(e)=>{}}>
                  <div>
                    <p>멤버쉽 요금 설정</p> 
                    <div className="fast-enroll-input-container">
                      <input type="text" placeholder='요금을 설정해주세요' value={memberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setMemberRate(Number(e.target.value))}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                    <p>비멤버쉽 요금 설정</p>
                    <div className="fast-enroll-input-container">
                      <input type="text" placeholder='요금을 설정해주세요' value={nonMemberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setNonMemberRate(Number(e.target.value))}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </form>
              </div>
              :
              <div className='cpo-fast-enroll-done-container'>
                <p>등록이 완료되었어요</p>
                <div onClick={()=>{navigator('/cpo/charger-management')}}><p>등록한 충전(소)기 보러가기</p></div>
              </div>
            }
            </div>
        </div>
        
    </div>
  )
}
export default CpoFastEnroll;