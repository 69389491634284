import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './store.js'
import { BrowserRouter } from "react-router-dom";
import axios from 'axios';
import {CookiesProvider} from 'react-cookie';
import { getCookie, setCookie, refresh } from './util/util.js';
const root = ReactDOM.createRoot(document.getElementById('root'));
axios.interceptors.response.use(
  (res)=>{

    return res;

  },
  async (err)=>{
    
    if(err.response.status===401){
      
      await refresh();
      // if(window.location.pathname == '/login')return;
      // window.location.replace('/login');
      // const accessExpires = new Date();
      // await axios.post("/auth/renewal" ,{},{
      //   headers: {
      //     Authorization: `Bearer ${refreshToken}`
      // }}).then(async(res)=>{
      //   accessExpires.setMinutes(accessExpires.getMinutes() + 14);

      //   const accessTokenData = {
      //     name: 'accessToken',
      //     data : res.data.response.accessToken,
      //     date : accessExpires
      //   }
      //   await window.api.send("set-cookie", accessTokenData);
      // }).catch((err)=>{
      //   console.log(err);
      //   window.location.replace('/login');

      // });
      // const accessToken = await window.api.get("get-cookies",'accessToken');

      // err.config.headers = {
      //   'Content-Type': 'application/json',
      //   Authorization: `Bearer ${accessToken}`,
      // };

      // // 중단된 요청을(에러난 요청)을 토큰 갱신 후 재요청
      // const response = await axios.request(err.config);
      // return response;
    }
    return Promise.reject(err);
  }
)
root.render(
  <Provider store={store}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
