import { useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import Toggle from '../buttons/Toggle.js';
import Selector from '../buttons/Selector.js';
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';

const ChargerDetailTab3 = (props) => {
  const navigate = useNavigate();
  const onCapture = () => {
    html2canvas(document.getElementById('imageWrapper')).then(
      (canvas) => {
        onSaveAs(canvas.toDataURL('image/png'), `${props.number}_QRcode.png`);
      }
    );
  };
  const onSaveAs = (uri, filename) => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = uri;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='charger-detail-tab tab3'>
      <div>
        <div className='charger-qrcode-container'>
          <div className="charger-qrcode-wrapper">
            <div className="charger-qrcode" id='imageWrapper'>
              {/* <p>QRCODE</p> */}
              {props.number?
              <QRCode value={props.number} />
              :<p>일련번호가 지정되어 있지 않습니다.</p>
              }
            </div>
          </div>
        </div>
        <div className='qrcode-download-button-container'>
          <div className='qrcode-download-button' onClick={(e)=>{onCapture()}}><p>QR코드 이미지 다운로드</p></div>
        </div>
      </div>
    </div>
  )
}
export default ChargerDetailTab3;
