import React, { useEffect, useState } from 'react';
import { CiFileOn } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { LuDownload } from "react-icons/lu";
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum, setDetailData} from '../../store.js'
import Toggle from '../buttons/Toggle.js';
import { MdFileUpload } from "react-icons/md";
import { TiDelete } from "react-icons/ti";


const StationDetailTab4 = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let detail = useSelector((state)=>state.detail);
  let dispatch = useDispatch();
  
  // tabData 형태 정의 필요
  const [tabData, setTabData] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  
  const[SiteUseAgreementDate, setSiteUseAgreementDate]=useState("");
  const[DateOfOccupancyPermission, setDateOfOccupancyPermission]=useState("");
  const[InstallationDate, setInstallationDate]=useState("");
  const[OperationStartDate, setOperationStartDate]=useState("");
  
  // 토글 클릭 여부 (관리자 상주 여부)
  const [WhetherTheManagerResides, setWhetherTheManagerResides] = useState(null);

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab4');
    if(temp){
      temp = JSON.parse(temp);
      setSiteUseAgreementDate(JSON.parse(temp['SiteUseAgreementDate']));
      setDateOfOccupancyPermission(JSON.parse(temp['DateOfOccupancyPermission']));
      setInstallationDate(JSON.parse(temp['InstallationDate']));
      setOperationStartDate(JSON.parse(temp['OperationStartDate']));
      setWhetherTheManagerResides(temp['WhetherTheManagerResides']);
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab4');
    if(!temp){
      console.log(props.data?.InstallationDate)
      setSiteUseAgreementDate(props.data?.StartSiteUseAgreementDate);
      setDateOfOccupancyPermission(props.data?.StartDateOfOccupancyPermission);
      setInstallationDate(props.data?.InstallationDate);
      setOperationStartDate(props.data?.OperationStartDate);
      setWhetherTheManagerResides(props.data?.WhetherTheManagerResides);
    }
  },[])

  useEffect(()=>{
    if(!props.open)return;
    if(SiteUseAgreementDate == ''&& DateOfOccupancyPermission =='' && InstallationDate=='' && OperationStartDate=='' && WhetherTheManagerResides==null)return;
    if(SiteUseAgreementDate == props.data.StartSiteUseAgreementDate&& DateOfOccupancyPermission ==props.data.StartDateOfOccupancyPermission && InstallationDate==props.data.InstallationDate && OperationStartDate==props.data.OperationStartDate && WhetherTheManagerResides==props.data.WhetherTheManagerResides)return;
    localStorage.setItem(`${props.name}`+'_StationDetailTab4', JSON.stringify( {SiteUseAgreementDate:JSON.stringify(SiteUseAgreementDate), DateOfOccupancyPermission:JSON.stringify(DateOfOccupancyPermission), InstallationDate:JSON.stringify(InstallationDate), OperationStartDate:JSON.stringify(OperationStartDate), WhetherTheManagerResides:WhetherTheManagerResides}))
    props.setSaveBtnStatus(true);
  },[SiteUseAgreementDate, DateOfOccupancyPermission, InstallationDate, OperationStartDate, WhetherTheManagerResides])



  return(
    <div className='station-detail-tab fourth'>
      <div className="station-detail-authorization-container">
        <div className="station-detail-child-grid">
          <div>
            <p className='station-detail-input-title'>부지사용협약일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={SiteUseAgreementDate?.split(' ')[0]} onChange={(e)=>{setSiteUseAgreementDate(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <UploadBox setFile={setFile1}/>
        </div>
        <div className="station-detail-child-grid">
          <div>
            <p className='station-detail-input-title'>점용허가일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={DateOfOccupancyPermission?.split(' ')[0]} onChange={(e)=>{setDateOfOccupancyPermission(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <UploadBox setFile={setFile1}/>
        </div>
        <div className="station-detail-child-grid">
          <div>
            <p className='station-detail-input-title'>설치일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={InstallationDate?.split(' ')[0]} onChange={(e)=>{setInstallationDate(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div>
            <p className='station-detail-input-title'>운영시작일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={OperationStartDate?.split(' ')[0]} onChange={(e)=>{setOperationStartDate(e.target.value);}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
        </div>
        <div>
          <p className='station-detail-input-title'>관리자 상주 여부</p>
          <div className='station-detail-authorization-tab-toggle-container'>
            <Toggle on={'상주'} off={'비상주'} onOff={WhetherTheManagerResides} setOnOff={setWhetherTheManagerResides}/>
          </div>
        </div>
        
      </div>
    </div>
  );
}
export default StationDetailTab4




const FileInfo = ({setUploadedInfo, uploadedInfo }) => (
  <div className="preview_info" onClick={(e)=>{e.preventDefault();e.stopPropagation();}}>
    <div className='file-info-container'>
      <div>
        <div><p className="info_value">{uploadedInfo.name}</p></div>
        <div><p className='info_value'>{uploadedInfo.size}</p></div>
      </div>
      <div>
        <TiDelete className='file-delete-icon' onClick={(e)=>{e.preventDefault();e.stopPropagation(); setUploadedInfo(null);}}/>
      </div>
    </div>
    {/* {Object.entries(uploadedInfo).map(([key, value]) => (
    ))} */}
  </div>
);
const blobToDataUrl = (blob) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = () => resolve(null);

    fileReader.readAsDataURL(blob);
    console.log()
    console.log(fileReader);
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = '';
    element.click();
  });
};

const UploadBox = () => {
  // redux
  let page = useSelector((state) => state.page );
  let detail = useSelector((state)=>state.detail);
  let dispatch = useDispatch();
  const [isActive, setActive] = useState(false);
  const [uploadedInfo, setUploadedInfo] = useState(null);
  const [file, setFile] = useState(null);
  
  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const setFileInfo = (file) => {
    if(!file)return;
    console.log(file);
    setFile(file)
    // const { name, size: byteSize, type } = file;
    const { name, size: byteSize } = file;
    const size = (byteSize / (1024 * 1024)).toFixed(2) + 'MB';
    // setUploadedInfo({ name, size, type }); // name, size, type 정보를 uploadedInfo에 저장
    setUploadedInfo({ name, size }); // name, size, type 정보를 uploadedInfo에 저장
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setActive(false);

    const file = event.dataTransfer.files[0];
    setFileInfo(file);
  };

  const handleUpload = ({ target }) => {
    const file = target.files[0];
    setFileInfo(file);
  };

  return (
    <div className='file-upload-wrapper'>
      <label
        className={`preview${isActive ? ' active' : ''}${uploadedInfo ? ' uploaded' : ''}`}
        onDragEnter={handleDragStart}
        onDragOver={handleDragOver}
        onDragLeave={handleDragEnd}
        onDrop={handleDrop}
      >
        <input type="file" className="file" onChange={handleUpload} />
      </label>
      {uploadedInfo &&<div className='file-uploaded-wrapper'>
        <div className='fileBtn' onClick={(e)=>{e.preventDefault(); e.stopPropagation(); blobToDataUrl(file)}}>
          <LuDownload/>
        </div>
        <FileInfo setUploadedInfo={setUploadedInfo} uploadedInfo={uploadedInfo} />
      </div>}
      {!uploadedInfo && (
        <div className='preview-container'>
          <MdFileUpload className='icon'/>
          <p className="preview_msg">클릭하거나 파일을 드롭하여 업로드</p>
        </div>
      )}
    </div>
  );
};