import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';

import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

export const SimpleLogin = () => {
  let navigator = useNavigate();
  
  const [passwordVisible, setPasswordVisible] = useState(false);

  const signedExpires = new Date();
  const refreshExpires = new Date();

  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const requestLogin = async()=>{
    console.log(email, password, checked);
    if(!email||!password){
      return;
    }
    await axios.post("/account/api/NearCpoAuth/signin", 
    {
      "email": email,
      "password": password,
      "rememberMe": checked
    },{withCredentials : true})
    .then(async(res)=>{
      console.log(res);
      // 쿠키에 토큰 저장
      signedExpires.setMinutes(signedExpires.getMinutes() + 14);
      refreshExpires.setDate(refreshExpires.getDate()+7);

      setCookie('refreshToken', res.data.RefreshToken, {
        path: '/',
        secure: '/',
        httpOnly: true,
        expires: refreshExpires
      });
      setCookie('signedToken', res.data.SignedToken, {
        path: '/',
        secure: '/',
        expires: signedExpires
      });
      
    })
    .then(()=>{
      // navigator('/cpo')
      console.log(getCookie('signedToken'));
      console.log(getCookie('refreshToken'));
      }
    )
    .catch((err)=>{
      console.log(err)
      // setErrorAlertVisible(true);
    })
  }

  return (
    <div className='login-page-container'>
      <div className="login-page-wrapper">
        <title>간편로그인</title>
        <p className="login-input-title">이메일</p>
        <div className='login-input-container email'><input placeholder='이메일을 입력하세요' type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/></div>
        <div className='login-button simple' onClick={(e)=>{e.stopPropagation();e.preventDefault();requestLogin();}}><p>로그인</p></div>
      </div>
    </div>
  )
}
export default SimpleLogin;