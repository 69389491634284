const MarkerShape = () => {
  let contentArray = [
    '<div style="width:fit-content; margin: 0; display: table; padding: 0.5rem; table-layout: auto; border-radius: 2.3rem; border: 0.2rem solid var(--emoovBlue); background: white; cursor: pointer; position: relative; z-index: 2">',
      '<div style="display: table-cell; display: inline-block; width: 1rem; height: 1rem; background-image: var(--stationMarker); transform:translate(1px, 1px); color:red; background-size: cover; background-position: center; background-repeat: no-repeat;"></div>',
      // '<span style="position: absolute; border-style: solid; border-width: 0.7rem 0.7rem 0 0.7rem; border-color: #ffffff transparent; display: block; width: 0; z-index: 1; top: 1.95rem; left: 0.3rem;"></span>',
      '<span style="position: absolute; border-style: solid; border-width: 0.95rem 0.8rem 0 0.8rem; border-color: var(--emoovBlue) transparent; display: block; width: 0; z-index: 0; top: 2.2rem; left: 0.3rem;"></span>',
    '</div>',
  ];
  
  return contentArray.join('');
};

export default MarkerShape;