import React from 'react'

const Toggle = (props) => {
  return (
    <div className={"toggle-wrapper " + `${props.disabled ? "disabled":""}`} onClick={()=>{props.setOnOff(!props.onOff)}}>
      <input className={"tgl " + `${props.onOff ? 'checked' : ''}`} name={props.name} type="checkbox"  checked={props.onOff} onChange={(e)=>{}}/>
      <label className="tgl-btn" htmlFor={props.name}></label>
      <p className={'toggle-content '+`${props.onOff ?'on':''}`}>{props.onOff ? props.on:props.off}</p>
    </div>
  )
}
export default Toggle;