import { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {setCookie, getCookie} from "../../util/util.js"
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
export const SignUpTab2 = (props) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordCheck, setPasswordCheck] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [businessNumber, setBusinessNumber] = useState(null);
  const [adminName, setAdminName] = useState(null);
  const [adminPhoneNumber, setAdminPhoneNumber] = useState(null);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(false);


  // Validation

  // 이메일 패턴 검사
  const [emailPatternValidate, setEmailPatternValidate] = useState(null); // false : 유효하지않은 이메일 형식, true: 이메일 형식
  // 이메일 중복여부
  const [emailDuplicate, setEmailDuplicate] = useState(null); //able : 사용가능  disable: 중복됨.
  // 이메일 유효성
  const [emailValidate, setEmailValidate] = useState(null); // false : 유효하지않은 이메일 형식, true: 유효
  const [passwordValidate, setPasswordValidate] = useState(null);
  const [passwordCheckValidate, setPasswordCheckValidate] = useState(null);
  const [businessNameValidate, setBusinessNameValidate] = useState(null);
  const [businessNumberValidate, setBusinessNumberValidate] = useState(null);
  const [adminNameValidate, setAdminNameValidate] = useState(null);
  const [adminPhoneNumberValidate, setAdminPhoneNumberValidate] = useState(null);


  useEffect(()=>{
    if(emailPatternValidate && emailDuplicate =="able" ){
      setEmailValidate(true);
    }
    else{
      setEmailValidate(false);
    }
  },[emailPatternValidate, emailDuplicate])

  // Validation Pattern
  const emailInput = (target)=>{
    target.value = target.value.replace(' ', '');
    const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
    if(pattern.test(target.value) === false) {setEmailPatternValidate(false); }
    else { setEmailPatternValidate(true); }
  }
  const passwordInput = (target) => {
    target.value = target.value.replace(' ', '');
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[!@#$%^&*])|(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*[!@#$%^&*])|(?=.*\d)(?=.*[!@#$%^&*]).*$/;
    const korCheck = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
    const value = target.value;
    // if(korCheck.test(value)) console.log('한/영 변환이 필요합니다.');
    if (value.length >= 6 && value.length <= 20 && pattern.test(value)) {
        setPasswordValidate(true);
    } else {
        setPasswordValidate(false);
    }
  };
  
  const businessNameInput = (target)=>{
    target.value = target.value.replace(' ', '');
    if (target.value == ""){setBusinessNameValidate(false)}
    else {setBusinessNameValidate(true)}
  }
  const businessNumberInput = (target)=>{
    target.value = target.value.replace(' ', '');
    if (target.value == ""){setBusinessNumberValidate(false)}
    else {setBusinessNumberValidate(true)}
  }
  const adminNameInput = (target)=>{
    target.value = target.value.replace(' ', '');
    if (target.value == ""){setAdminNameValidate(false)}
    else {setAdminNameValidate(true)}
  }
  const adminPhoneNumberInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
    if (target.value == ""){setAdminPhoneNumberValidate(false)}
    else {setAdminPhoneNumberValidate(true)}
  }

  useEffect(()=>{
    if(passwordCheck == null || password == null)return;
    if (passwordCheck == password){
      if(passwordCheck == "" || password == "")return;
      setPasswordCheckValidate(true);
    }
    else{
      setPasswordCheckValidate(false);
    }
  },[passwordCheck, password])




  const checkDuplication = async()=>{
    // /account/api/NearCpoAuth/exist-email?email=
    await axios.get(`/account/api/NearCpoAuth/exist-email?email=${email}`, 
    {},{withCredentials : true})
    .then(async(res)=>{
      console.log(res);
      setEmailDuplicate("able");
      
    })
    .catch((err)=>{
      console.log(err);
      setEmailDuplicate('disable');
    })
  }


  const signUpValidationBusiness = ()=>{
    if(emailValidate&&passwordValidate&&passwordCheckValidate&&businessNameValidate&&businessNumberValidate&&adminNameValidate&&adminPhoneNumberValidate){
      return true;
    }
    else{
      if (!emailValidate)setEmailValidate("invalid");
      if(!passwordValidate)setPasswordValidate("invalid")
      if(!passwordCheckValidate)setPasswordCheckValidate("invalid")
      if(!businessNameValidate)setBusinessNameValidate("invalid")
      if(!businessNumberValidate)setBusinessNumberValidate("invalid")
      if(!adminNameValidate)setAdminNameValidate("invalid")
      if(!adminPhoneNumberValidate)setAdminPhoneNumberValidate("invalid")
      return false;
    }
  }
  const signUpValidationNormal = ()=>{
    if(emailValidate&&passwordValidate&&passwordCheckValidate&&businessNameValidate){
      return true;
    }
    else{
      if (!emailValidate)setEmailValidate("invalid");
      if(!passwordValidate)setPasswordValidate("invalid")
      if(!passwordCheckValidate)setPasswordCheckValidate("invalid")
      if(!businessNameValidate)setBusinessNameValidate("invalid")
      return false;
    }
  }



  const requestSignUpBusiness = async()=>{
    if(!signUpValidationBusiness())return;
    console.log({
      "email": email,
      "password": password,
      "businessRegistrationNumber": businessNumber,
      "businessName": businessName,
      "managerPhoneNumber": adminPhoneNumber,
      "managerName": adminName
    })
    await axios.post("/account/api/NearCpoAuth/CpoSignUp", 
    {
      "email": email,
      "password": password,
      "businessRegistrationNumber": businessNumber,
      "businessName": businessName,
      "managerPhoneNumber": adminPhoneNumber,
      "managerName": adminName
    },{withCredentials : true})
    .then((res)=>{
      console.log(res);
      props.setEmail(email);
      props.setStep(2)
    })
    .catch((err)=>{
      console.log(err);
      // 사업자 등록번호 ErrorCode
      if(err.response.data.ErrorCode == "INVALID_BUSINESS_REGISTRATION_NUMBER"){
        setBusinessNumberValidate("invalid")
      }
      if(err.response.data.ErrorCode == "ALREADY_EXIST_BUSINESS_REGISTRATION_NUMBER"){
        setBusinessNumberValidate("invalid")
      }
      // 이메일 중복
      if(err.response.data.ErrorCode == "ALREADY_EXIST_EMAIL"){
        setEmailValidate("invalid");
        setEmailDuplicate('disable');
      }
      
    })
  }
  const requestSignUpNormal = async()=>{
    if(!signUpValidationNormal())return;
    console.log({
      "email": email,
      "password": password,
      "realName": businessName,
    })
    await axios.post("/account/api/NearCpoAuth/personal-signup", 
    {
      "email": email,
      "password": password,
      "realName": businessName,
    },{withCredentials : true})
    .then((res)=>{
      console.log(res);
      props.setEmail(email);
      props.setStep(2)
    })
    .catch((err)=>{
      console.log(err);
      // 이메일 중복
      if(err.response.data.ErrorCode == "ALREADY_EXIST_EMAIL"){
        setEmailValidate("invalid");
        setEmailDuplicate('disable');
      }
    })
  }

  return (
    <div className="sign-up-tab-container sign-up-tab2">
      {
        props.target == "business"?
        <>
          <p className="sign-up-tab-title">
            사업자 정보
          </p>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">이메일</p>
            <div className={'sign-up-input-container email' + `${emailValidate == 'invalid' ? ' invalid':""}`}><input placeholder='이메일' type="email" value={email} onChange={(e)=>{setEmail(e.target.value); setEmailDuplicate(null);}} onInput={(e)=>{emailInput(e.target)}}/></div>
            <div className={'sign-up-duplication-check-button ' + `${emailPatternValidate ? "" : "none"}`} onClick={(e)=>{e.preventDefault(); checkDuplication();}}><p>중복확인</p></div>
            {
              emailValidate !=="invalid" && emailValidate ?
                <p className={'sign-up-validation-msg able'}>사용가능한 이메일입니다.</p> //유효함
              :
                //유효하지 않음.
                emailPatternValidate ?
                  emailDuplicate == 'disable' ?
                    <p className={'sign-up-validation-msg alert'}>중복된 이메일입니다.</p>
                    :
                    <p className={'sign-up-validation-msg alert'}>중복 확인 버튼을 눌러주세요.</p>
                :
                  !email && emailValidate !=="invalid" ? <></>:<p className={'sign-up-validation-msg alert'}>유효하지 않은 이메일 형식입니다.</p>
            }
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">비밀번호</p>
            <div className={'sign-up-input-container password ' + `${passwordValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호' type={passwordVisible ? "text" : "password"} autocomplete="new-password" value={password} onChange={(e)=>{setPassword(e.target.value)}} onInput={(e)=>{passwordInput(e.target)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div>
            {passwordValidate && passwordValidate!=="invalid" ? <p className={'sign-up-validation-msg able' }>유효한 비밀번호입니다.</p> : password!==null || passwordValidate=="invalid" ? <p className={'sign-up-validation-msg alert' }>영문 대문자와 소문자, 숫자, 특수문자 중 2가지 이상을 조합하여 6~20자로 입력해주세요.</p>:<></>}
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">비밀번호 재확인</p>
            <div className={'sign-up-input-container password-check '+ `${passwordCheckValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호 확인' type={passwordCheckVisible ? "text" : "password"} autocomplete="new-password" value={passwordCheck} onChange={(e)=>{setPasswordCheck(e.target.value)}} onInput={(e)=>{e.target.value = e.target.value.replace(' ', '');}}/> {passwordCheckVisible ? <IoMdEyeOff onClick={()=>setPasswordCheckVisible(false)}/> : <IoMdEye onClick={()=>setPasswordCheckVisible(true)}/>}</div>
            {passwordCheckValidate !== "invalid" &&(passwordCheckValidate || passwordCheck == null) ? <></> : <p className={'sign-up-validation-msg alert'}>비밀번호가 일치하지 않습니다.</p>}
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">사업자 이름</p>
            <div className={'sign-up-input-container business-name '+ `${businessNameValidate == 'invalid' ? ' invalid':""}`}><input placeholder='이름' type={"text"} autocomplete="new-password" value={businessName} onChange={(e)=>{setBusinessName(e.target.value)}} onInput={(e)=>{businessNameInput(e.target)}}/></div>
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">사업자 등록번호</p>
            <div className={'sign-up-input-container business-number '+ `${businessNumberValidate == 'invalid' ? ' invalid':""}`}><input placeholder='등록번호' type={"text"} autocomplete="new-password" value={businessNumber} onChange={(e)=>{setBusinessNumber(e.target.value)}} onInput={(e)=>{businessNumberInput(e.target)}}/></div>
            {businessNumberValidate !== "invalid" ? <></> : <p className={'sign-up-validation-msg alert'}>사업자 등록번호가 유효하지 않습니다.</p>}
          </div>

          <p className="sign-up-tab-title admin">
            담당자 정보
          </p>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">담당자 이름</p>
            <div className={'sign-up-input-container admin-name '+ `${adminNameValidate == 'invalid' ? ' invalid':""}`}><input placeholder='담당자 이름' type={"text"} autocomplete="new-password" value={adminName} onChange={(e)=>{setAdminName(e.target.value)}} onInput={(e)=>{adminNameInput(e.target)}}/></div>
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">담당자 휴대폰 번호</p>
            <div className={'sign-up-input-container admin-phone-number '+ `${adminPhoneNumberValidate == 'invalid' ? ' invalid':""}`}><input placeholder='담당자 휴대폰 번호' type={"text"} autocomplete="new-password" value={adminPhoneNumber} onChange={(e)=>{setAdminPhoneNumber(e.target.value)}} onInput={(e)=>{adminPhoneNumberInput(e.target)}}/></div>
          </div>


          <div className='sign-up-go-to-container'>
            <div className='sign-up-go-to-button back' onClick={()=>{props.setStep(0)}}><p>이전</p></div>
            <div className='sign-up-go-to-button request' onClick={()=>{requestSignUpBusiness()}}><p>회원가입</p></div>
          </div>
        </>
        :
        <>
          <p className="sign-up-tab-title">
            기본 정보
          </p>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">이메일</p>
            <div className={'sign-up-input-container email' + `${emailValidate == 'invalid' ? ' invalid':""}`}><input placeholder='이메일' type="email" value={email} onChange={(e)=>{setEmail(e.target.value); setEmailDuplicate(null);}} onInput={(e)=>{emailInput(e.target)}}/></div>
            <div className={'sign-up-duplication-check-button ' + `${emailPatternValidate ? "" : "none"}`} onClick={(e)=>{e.preventDefault(); checkDuplication();}}><p>중복확인</p></div>
            {
              emailValidate !=="invalid" && emailValidate ?
                <p className={'sign-up-validation-msg able'}>사용가능한 이메일입니다.</p> //유효함
              :
                //유효하지 않음.
                emailPatternValidate ?
                  emailDuplicate == 'disable' ?
                    <p className={'sign-up-validation-msg alert'}>중복된 이메일입니다.</p>
                    :
                    <p className={'sign-up-validation-msg alert'}>중복 확인 버튼을 눌러주세요.</p>
                :
                  !email && emailValidate !=="invalid" ? <></>:<p className={'sign-up-validation-msg alert'}>유효하지 않은 이메일 형식입니다.</p>
            }
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">비밀번호</p>
            <div className={'sign-up-input-container password ' + `${passwordValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호' type={passwordVisible ? "text" : "password"} autocomplete="new-password" value={password} onChange={(e)=>{setPassword(e.target.value)}} onInput={(e)=>{passwordInput(e.target)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div>
            {passwordValidate && passwordValidate!=="invalid" ? <p className={'sign-up-validation-msg able' }>유효한 비밀번호입니다.</p> : password!==null || passwordValidate=="invalid" ? <p className={'sign-up-validation-msg alert' }>영문 대문자와 소문자, 숫자, 특수문자 중 2가지 이상을 조합하여 6~20자로 입력해주세요.</p>:<></>}
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">비밀번호 재확인</p>
            <div className={'sign-up-input-container password-check '+ `${passwordCheckValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호 확인' type={passwordCheckVisible ? "text" : "password"} autocomplete="new-password" value={passwordCheck} onChange={(e)=>{setPasswordCheck(e.target.value)}} onInput={(e)=>{e.target.value = e.target.value.replace(' ', '');}}/> {passwordCheckVisible ? <IoMdEyeOff onClick={()=>setPasswordCheckVisible(false)}/> : <IoMdEye onClick={()=>setPasswordCheckVisible(true)}/>}</div>
            {passwordCheckValidate !== "invalid" &&(passwordCheckValidate || passwordCheck == null) ? <></> : <p className={'sign-up-validation-msg alert'}>비밀번호가 일치하지 않습니다.</p>}
          </div>
          <div className='sign-up-input-row'>
            <p className="sign-up-input-title">이름</p>
            <div className={'sign-up-input-container business-name '+ `${businessNameValidate == 'invalid' ? ' invalid':""}`}><input placeholder='이름' type={"text"} autocomplete="new-password" value={businessName} onChange={(e)=>{setBusinessName(e.target.value)}} onInput={(e)=>{businessNameInput(e.target)}}/></div>
          </div>
          <div className='sign-up-go-to-container'>
            <div className='sign-up-go-to-button back' onClick={()=>{props.setStep(0)}}><p>이전</p></div>
            <div className='sign-up-go-to-button request' onClick={()=>{requestSignUpNormal()}}><p>회원가입</p></div>
          </div>
        </>
      }
      
    </div>
  )
}
export default SignUpTab2;