import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { getCookie } from '../../util/util.js';

export const StationEnrollModal = (props) => {
  let signedToken = getCookie("signedToken");
  const [stationName, setStationName] = useState("");
  const [stationSerial, setStationSerial] = useState("");
  function onInput(target){
    target.value = target.value.replace(/[^a-zA-Z]/g, '');
  }
  const [cpoId, setCpoId] = useState(null);
  useEffect(()=>{
    setCpoId(localStorage.getItem('cpoId'));
  },[])

  
  const enrollReq = async()=>{
    if(cpoId == null)return;
    // /infra/api/ChargingStations
    await axios.post(`/infra/api/ChargingStations`,
    {
      cpoId : cpoId,
      chargingStationName : stationName,
      '충전소 일련변호': stationSerial
    },
    {
      headers: { 'content-type': 'application/json-patch+json' },
    }
    ).then((res)=>{
    })
  }
  const [nameContent, setNameContent] = useState();
  const [nameContentValidation, setNameContentValidation] = useState(null);
  const [nameContentDuplication, setNameContentDuplication] = useState(null);
  
  const [stationValidation, setStationValidation] = useState(null);
  const stationNameDuplicationCheck = async()=>{
    await axios.get(`/infra/api/cpouser/v1/ChargingStations/isExist?codeName=${nameContent}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      if(!res.data){
        setNameContentDuplication(true);
      }
      else{
        setNameContentDuplication("disable");
      }
    })
  }

  return (
    <div className="cpo-station-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-station-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>충전소 등록</title>
        </div>
        <div className="fast-enroll-input-container name">
          <input type="text" placeholder='충전소 명을 입력해주세요' value={nameContent} onChange={(e)=>{ setNameContent(e.target.value); setNameContentDuplication(null);}} onClick={(e)=>{e.stopPropagation(); }}/>
          <div className={`fast-enroll-station-name-validation ` + `${nameContent==""||!nameContent?"disable":""}`} onClick={()=>{stationNameDuplicationCheck()}}><p>중복확인</p></div>
          {
            nameContentDuplication == "disable" ? 
              <p className={'fast-enroll-input-validation-msg alert'}>중복된 충전소명 입니다.</p>
            :
              nameContentDuplication ? 
                <p className={'fast-enroll-input-validation-msg able'}>사용가능한 충전소명 입니다.</p>
              :
                nameContent == "" || nameContent == null ?
                  nameContentValidation == "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>충전소명을 입력해주세요.</p>:<></>
                :
                <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
          }
        </div>
        <div>
          <div></div>
          <div className={'cpo-station-modal-enroll-button '+ `${nameContent!=="" && nameContentDuplication !== "disable" && nameContentDuplication  ?"":"none"}`} onClick={()=>{enrollReq()}}><p>등록</p></div>
        </div>
      </div>
    </div>
  )
}
export default StationEnrollModal;