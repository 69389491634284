import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import Toggle from '../buttons/Toggle.js';
import Selector from '../buttons/Selector.js';
import { MdElectricCar } from "react-icons/md";
import { RxSlash } from "react-icons/rx";


const StationDetailTab1 = (props) => {


  // 토글 클릭 여부
  const [IsItInOperation, setIsItInOperation] = useState(null);

  // 선택자 클릭 여부
  const [OperatorId, setOperatorId] = useState("");
  const [OperatorIdVal, setOperatorIdVal] = useState("");
  const [OperatorIdActive, setOperatorIdActive] = useState(null);

  const [OwnerId, setOwnerId] = useState("");
  const [OwnerIdVal, setOwnerIdVal] = useState("");
  const [OwnerIdActive, setOwnerIdActive] = useState(null);

  const [FacilityClassification, setFacilityClassification] = useState("");
  const [FacilityClassificationVal, setFacilityClassificationVal] = useState("");
  const [FacilityClassificationActive, setFacilityClassificationActive] = useState(null);
  
  const [FacilitySubdivision, setFacilitySubdivision] = useState("");
  const [FacilitySubdivisionVal, setFacilitySubdivisionVal] = useState("");
  const [FacilitySubdivisionActive, setFacilitySubdivisionActive] = useState(null);

  const [parkingFeeType, setParkingFeeType] = useState("");
  const [parkingFeeTypeVal, setParkingFeeTypeVal] = useState("");
  const [parkingFeeTypeActive, setParkingFeeTypeActive] = useState(null);

  const[StartAvailableTime, setStartAvailableTime]=useState("");
  const[EndAvailableTime, setEndAvailableTime]=useState("");
  const[ParkingFee, setParkingFee]=useState("");
  const[NumberOfParkingSpaces, setNumberOfParkingSpaces]=useState("");

  const activeReset = ()=>{
    setOperatorIdActive(false);
    setOwnerIdActive(false);
    setFacilityClassificationActive(false);
    setFacilitySubdivisionActive(false);        
    setParkingFeeTypeActive(false);        
  }

  const operatorIDList =[
    {id:'a',name:'홍길동'},
    {id:'b',name:'김길동'},
    {id:'c',name:'이길동'},
  ];
  const ownerIDList =[
    {id:'a',name:'홍동갈'},
    {id:'b',name:'김동길'},
    {id:'c',name:'이동길'},
  ];
  const facilityClassificationList = [
    {id:'A0', name:"공공시설"},
    {id:'B0', name:"주차시설"},
    {id:'C0', name:"휴게시설"},
    {id:'D0', name:"관광시설"},
    {id:'E0', name:"상업시설"},
    {id:'F0', name:"차량정비시설"},
    {id:'G0', name:"기타시설"},
    {id:'H0', name:"공동주택시설"},
    {id:'I0', name:"근린생활시설"},
    {id:'J0', name:"교육문화시설"},
  ];
  const facilitySubdivisionList = {
    'A0':[
      {id:'01', name:"관공서"},
      {id:'02', name:"주민센터"},
      {id:'03', name:"공공기관"},
      {id:'04', name:"지차체시설"}
    ],
    'B0':[
      {id:'01', name: "공영주차장"},
      {id:'02', name: "공원주차장"},
      {id:'03', name: "환승주차장"},
      {id:'04', name: "일반주차장"},
    ],
    'C0':[
      {id:'01', name:"고속도로 휴게소"},
      {id:'02', name:"지방도로 휴게소"},
      {id:'03', name:"쉼터"},
    ],
    'D0':[
      {id:'01', name:"공원"},
      {id:'02', name:"전시관"},
      {id:'03', name:"민속마을"},
      {id:'04', name:"생태공원"},
      {id:'05', name:"홍보관"},
      {id:'06', name:"관광안내소"},
      {id:'07', name:"관광지"},
      {id:'08', name:"박물관"},
      {id:'09', name:"유적지"},
    ],
    'E0':[
      {id:'01', name:'마트(쇼핑몰)'},
      {id:'02', name:'백화점'},
      {id:'03', name:'숙박시설'},
      {id:'04', name:'골프장'},
      {id:'05', name:'카페'},
      {id:'06', name:'음식점'},
      {id:'07', name:'주유소'},
      {id:'08', name:'영화관'},
    ],
    'F0':[
      {id:"01", name:"서비스센터"},
      {id:"02", name:"정비소"},
    ],
    'G0':[
      {id:"01",name:"군부대"},
      {id:"02",name:"야영장"},
      {id:"03",name:"공중전화부스"},
      {id:"04",name:"기타"},
      {id:"05",name:"오피스텔"},
      {id:"06",name:"단독주택"},
    ],
    'H0':[
      {id:"01", name:"아파트"},
      {id:"02", name:"빌라"},
      {id:"03", name:"사업장(사옥)"},
      {id:"04", name:"기숙사"},
      {id:"05", name:"연립주택"},
    ],
    'I0':[
      {id:"01",name:"병원"},
      {id:"02",name:"종교시설"},
      {id:"03",name:"보건소"},
      {id:"04",name:"경찰서"},
      {id:"05",name:"도서관"},
      {id:"06",name:"복지관"},
      {id:"07",name:"수련원"},
      {id:"08",name:"금융기관"},
    ],
    'J0':[
      {id:"01",name:"학교"},
      {id:"02",name:"교육원"},
      {id:"03",name:"학원"},
      {id:"04",name:"공연장"},
      {id:"05",name:"관람장"},
      {id:"06",name:"동식물원"},
      {id:"07",name:"경기장"},
    ],
  };
  const parkingFeeTypeList = [
    {id:"FREE",name:"무료"},
    {id:"PAID",name:"유료"},
    {id:"CONDITIONAL_PAID",name:"조건무료"},
  ];

  function timeInput(time) {
    time.value = time.value.replace(/[^0-9]/g, '');
    var replaceTime = time.value.replace(/\:/g, "");
    var hours = replaceTime.substring(0, 2); // 2400->24
    var minute = replaceTime.substring(2, 4); //2400->00

    if(replaceTime.length >= 4) {
        time.value = hours + ":" + minute;
        
        if(hours + minute >= 2400) {
          time.value = "00:00";
            return false;
        }else if(minute >= 60) { 
            time.value = hours + ":00";
            return false;
        }
    }
  }

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab1');
    if(temp){
      console.log("Hell2222");
      temp = JSON.parse(temp);
      setOperatorId(JSON.parse(temp['OperatorId']));
      setOperatorIdVal(operatorIDList.filter(it=>it.name == JSON.parse(temp['OperatorId']))[0]);
      setOwnerId(JSON.parse(temp['OwnerId']));
      setOwnerIdVal(ownerIDList.filter(it=>it.name == JSON.parse(temp['OwnerId']))[0]);
      setFacilityClassification(JSON.parse(temp['FacilityClassification']));
      let classTemp = facilityClassificationList.filter(it=>it.name == JSON.parse(temp['FacilityClassification']))[0]
      console.log(classTemp);
      setFacilityClassificationVal(classTemp);
      setFacilitySubdivision(JSON.parse(temp['FacilitySubdivision']));
      setFacilitySubdivisionVal(classTemp ? facilitySubdivisionList[classTemp.id]?.filter(it=>it.name == JSON.parse(temp['FacilitySubdivision']))[0] : "");
      setParkingFeeType(JSON.parse(temp['parkingFeeType']));
      setParkingFeeTypeVal(parkingFeeTypeList.filter(it=>it.id ==JSON.parse(temp['parkingFeeType']))[0]);
      setStartAvailableTime(JSON.parse(temp['StartAvailableTime']));
      setEndAvailableTime(JSON.parse(temp['EndAvailableTime']));
      setParkingFee(JSON.parse(temp['ParkingFee']));
      setNumberOfParkingSpaces(JSON.parse(temp['NumberOfParkingSpaces']));
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab1');
    console.log(temp);
    if(temp)return;
    console.log("Hell");
    setOperatorId(props.data?.OperatorId);
    setOperatorIdVal(operatorIDList.filter(it=>it.name == props.data?.OperatorId)[0]);
    setOwnerId(props.data?.OwnerId);
    setOwnerIdVal(ownerIDList.filter(it=>it.name == props.data?.OwnerId)[0]);
    setFacilityClassification(props.data?.FacilityClassification);
    let classTemp = facilityClassificationList.filter(it=>it.name == props.data?.FacilityClassification)[0]
    setFacilityClassificationVal(classTemp);
    setFacilitySubdivision(props.data?.FacilitySubdivision);
    setFacilitySubdivisionVal(classTemp ? facilitySubdivisionList[classTemp.id]?.filter(it=>it.name == props.data?.FacilitySubdivision)[0] : "");
    console.log(props.data?.ParkingFeeType)
    setParkingFeeType(props.data?.ParkingFeeType);
    console.log(parkingFeeTypeList.filter(it=>it.id ==props.data?.ParkingFeeType)[0])
    setParkingFeeTypeVal(parkingFeeTypeList.filter(it=>it.id ==props.data?.ParkingFeeType)[0]);

    setStartAvailableTime(props.data?.StartAvailableTime);
    setEndAvailableTime(props.data?.EndAvailableTime);
    setParkingFee(props.data?.ParkingFee);
    setNumberOfParkingSpaces(props.data?.NumberOfParkingSpaces);
  },[])

  useEffect(()=>{
    if(!OperatorIdVal)return;
    setOperatorId(OperatorIdVal.name);
  },[OperatorIdVal])
  
  useEffect(()=>{
    if(!OwnerIdVal)return;
    setOwnerId(OwnerIdVal.name);
  },[OwnerIdVal])
  
  useEffect(()=>{
    if(!FacilityClassificationVal)return;
    setFacilityClassification(FacilityClassificationVal.name);
  },[FacilityClassificationVal])
  
  useEffect(()=>{
    if(!FacilitySubdivisionVal)return;
    setFacilitySubdivision(FacilitySubdivisionVal.name);
  },[FacilitySubdivisionVal])
  
  useEffect(()=>{
    if(!parkingFeeTypeVal)return;
    setParkingFeeType(parkingFeeTypeVal.id);
  },[parkingFeeTypeVal])


  useEffect(()=>{
    if(!props.open)return;
    if(OperatorId == ''&& OwnerId =='' && FacilityClassification=='' && FacilitySubdivision=='' && parkingFeeType=='' && StartAvailableTime=='' && EndAvailableTime=='' && ParkingFee=='' && NumberOfParkingSpaces=='' && IsItInOperation==null)return;
    if(OperatorId == props.data.OperatorId && OwnerId ==props.data.OwnerId  && FacilityClassification==props.data.FacilityClassification && FacilitySubdivision==props.data.FacilitySubdivision && parkingFeeType==props.data.parkingFeeType && StartAvailableTime==props.data.StartAvailableTime && EndAvailableTime==props.data.EndAvailableTime && ParkingFee==props.data.ParkingFee && NumberOfParkingSpaces==props.data.NumberOfParkingSpaces)return;
    localStorage.setItem(`${props.name}`+'_StationDetailTab1', JSON.stringify( {OperatorId:JSON.stringify(!OperatorId?"":OperatorId), OwnerId:JSON.stringify(!OwnerId?"":OwnerId), FacilityClassification:JSON.stringify(!FacilityClassification?"":FacilityClassification), FacilitySubdivision:JSON.stringify(!FacilitySubdivision?"":FacilitySubdivision), parkingFeeType:JSON.stringify(!parkingFeeType?"":parkingFeeType), StartAvailableTime:JSON.stringify(!StartAvailableTime?"":StartAvailableTime), EndAvailableTime:JSON.stringify(!EndAvailableTime?"":EndAvailableTime), ParkingFee:JSON.stringify(!ParkingFee?"":ParkingFee), NumberOfParkingSpaces:JSON.stringify(!NumberOfParkingSpaces?"":NumberOfParkingSpaces)}))
    props.setSaveBtnStatus(true);
  },[OperatorId, OwnerId, FacilityClassification, FacilitySubdivision, parkingFeeType, StartAvailableTime, EndAvailableTime, ParkingFee, NumberOfParkingSpaces])


  return (
    <div className='station-detail-tab tab1'>
      <div>
        <div className='station-detail-tab-selector-container'>
          <p className='station-detail-input-title'>충전기 구분 (대)</p>
          <Selector grid={true} dataList={facilityClassificationList} select={FacilityClassificationVal} setSelect={setFacilityClassificationVal} active={FacilityClassificationActive} setActive={setFacilityClassificationActive} activeReset={activeReset}/>
        </div>
        <div className='station-detail-tab-selector-container'>
          <p className='station-detail-input-title'>충전기 구분 (소)</p>
          <Selector grid={true} disabled={FacilityClassification?'':true} dataList={FacilityClassificationVal?.id?facilitySubdivisionList[FacilityClassificationVal.id]:[]} select={FacilitySubdivisionVal} setSelect={setFacilitySubdivisionVal} active={FacilitySubdivisionActive} setActive={setFacilitySubdivisionActive} activeReset={activeReset}/>
        </div>
      </div>
      <div>
        <div className='station-detail-tab-selector-container'>
          <p className='station-detail-input-title'>운영사</p>
          <Selector append={true} dataList={operatorIDList} select={OperatorIdVal} setSelect={setOperatorIdVal} active={OperatorIdActive} setActive={setOperatorIdActive} activeReset={activeReset}/>
        </div>
        <div className='station-detail-tab-selector-container'>
          <p className='station-detail-input-title'>소유주</p>
          <Selector append={true} dataList={ownerIDList} select={OwnerIdVal} setSelect={setOwnerIdVal} active={OwnerIdActive} setActive={setOwnerIdActive} activeReset={activeReset}/>
        </div>
      </div>
      <div>
        <div>
          <p className='station-detail-input-title'>충전기 운용 현황</p>
          <div className='station-detail-charger-status-container'>
            <div>
              <p>{props.chargerHubInfo?.filter(it=>it.used == true).length}</p>
            </div>
            <div>
              <p>{props.data?.ChargerIds.length}</p>
            </div>
          </div>
        </div>
        <div className='station-detail-child-grid'>
          <div className='station-detail-info-input-wrapper'>
            <p>시작 시간</p>
            <div className="station-detail-info-input-container time">
              <input type="text" placeholder='' onInput={(e)=>{timeInput(e.target)}} value={StartAvailableTime} onChange={(e)=>{setStartAvailableTime(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
            <p>부터</p>
          </div>
          <div className='station-detail-info-input-wrapper'>
            <p>시작 시간</p>
            <div className="station-detail-info-input-container time">
              <input type="text" placeholder='' onInput={(e)=>{timeInput(e.target)}} value={EndAvailableTime} onChange={(e)=>{setEndAvailableTime(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
            <p>부터</p>
          </div>
        </div>
      </div>
      <div>
        <div className='station-detail-tab-selector-container'>
          <p className='station-detail-input-title'>주차료 유무</p>
          <Selector dataList={parkingFeeTypeList} select={parkingFeeTypeVal} setSelect={setParkingFeeTypeVal} active={parkingFeeTypeActive} setActive={setParkingFeeTypeActive} activeReset={activeReset}/>
        </div>
        <div className='station-detail-child-grid'>
          <div className='station-detail-info-input-wrapper'>
            <p>주차비</p>
            <div className="station-detail-info-input-container parkingFee">
              <input type="text" placeholder='' value={ParkingFee} onChange={(e)=>{setParkingFee(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className='station-detail-info-input-wrapper'>
            <p>주차면 개수</p>
            <div className="station-detail-info-input-container parkingSpace">
              <input type="text" placeholder='' value={NumberOfParkingSpaces} onChange={(e)=>{setNumberOfParkingSpaces(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/> 
            </div>
            <p>개</p>
          </div>




        </div>
      </div>

    </div>
  )
}
export default StationDetailTab1;
