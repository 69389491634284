import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { CiUser } from "react-icons/ci";
import { FaRegBuilding } from "react-icons/fa";
import Toggle from '../buttons/Toggle';
import { setCookie, getCookie } from '../../util/util';

export const PaymentCardEnrollModal = (props) => {
  
  const signedToken =  getCookie('signedToken')
  function onInput(target){
    target.value = target.value.replace(/[^a-zA-Z]/g, '');
  }
  const [cpoId, setCpoId] = useState(null);
  useEffect(()=>{
    setCpoId(localStorage.getItem('cpoId'));
  },[])

  

    const [cardNumber, setCardNumber] = useState("");
    const [cardYY, setCardYY] = useState("");
    const [cardMM, setCardMM] = useState("");
    const [cvc, setCvc] = useState("");
    const [customerIdentityNumber, setCustomerIdentityNumber] = useState("");
    const [partialPassword, setPartialPassword] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [cardName, setCardName] = useState("");
    const [defaultCard, setDefaultCard] = useState(false);


  
  const enrollReq = async()=>{
    if(cpoId == null)return;
    await axios.post(`/payment/api/cpouser/PaymentCards`,
    {
      "cardNumber": cardNumber,
      "cardYY": cardYY,
      "cardMM": cardMM,
      "cvc": cvc,
      "customerIdentityNumber": customerIdentityNumber,
      "partialPassword": partialPassword,
      "customerName": customerName,
      "customerEmail": customerEmail,
      "cardName": cardName,
      "defaultCard": defaultCard
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  ).then((res)=>{
    props.getCardList();
  })
  props.setEnrollModalOn(false);
  }

  const cardNumInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
  }
  const [enrollTab, setEnrollTab] = useState(0);
  const [isItDefault, setIsItDefault] = useState(false);
  return (
    <div className="cpo-payment-card-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-payment-card-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-payment-card-enroll-modal-header">
          <title>결제카드 등록</title>
        </div>
        {
          enrollTab == 0 ?
          <>
            <div className='card-type-select-button-container'>
              <div className='card-type-select-button' onClick={()=>{setEnrollTab(1)}}>
                <CiUser/>
                <p><strong>개인</strong> 카드</p>
              </div>
              <div className='card-type-select-button' onClick={()=>{setEnrollTab(2)}}>
                <FaRegBuilding/>
                <p><strong>법인</strong> 카드</p>
              </div>
            </div>
            <div></div>
          </>
          :
          enrollTab == 1 ?
          <>
            <p className='cpo-payment-card-enroll-modal-title'>카드 정보</p>
            <div className='cpo-payment-card-enroll-input-title'><p>카드 번호</p></div>
            <div className="cpo-payment-card-enroll-modal-input-container">
              <input type="text" placeholder='카드 번호' value={cardNumber} onChange={(e)=>{ setCardNumber(e.target.value)}} onInput={(e)=>{cardNumInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
            
            <div className='cpo-payment-card-enroll-row-2'>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>유효 일자</p></div>
                <div className='cpo-payment-card-enroll-date-input-wrapper'>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='YY' value={cardYY} onChange={(e)=>{ setCardYY(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='MM' value={cardMM} onChange={(e)=>{ setCardMM(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                </div>
              </div>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>생년월일</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='YYMMDD' value={customerIdentityNumber} onChange={(e)=>{ setCustomerIdentityNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
            </div>
            <div className='cpo-payment-card-enroll-row-3'>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>카드 비밀번호</p></div>
                <div className='cpo-payment-card-enroll-input-wrapper'>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='' value={partialPassword} onChange={(e)=>{ setPartialPassword(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                  <p>**</p>
                </div>
              </div>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>CVC</p></div>
                <div className='cpo-payment-card-enroll-input-wrapper'>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='' value={cvc} onChange={(e)=>{ setCvc(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="cpo-payment-card-enroll-row-4">
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>카드 명</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='' value={cardName} onChange={(e)=>{ setCardName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>기본 결제카드로 설정</p></div>
                <Toggle on={'예'} off={'아니오'} onOff={defaultCard} setOnOff={setDefaultCard}/>
              </div>
            </div>
            
            <div className='cpo-payment-card-enroll-customer-info-row'>
              <p className='cpo-payment-card-enroll-modal-title'>고객 정보</p>
              <div className='cpo-payment-card-enroll-input-title'><p>이름</p></div>
              <div className="cpo-payment-card-enroll-modal-input-container">
                <input type="text" placeholder='' value={customerName} onChange={(e)=>{ setCustomerName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div className='cpo-payment-card-enroll-input-title'><p>이메일</p></div>
              <div className="cpo-payment-card-enroll-modal-input-container">
                <input type="text" placeholder='' value={customerEmail} onChange={(e)=>{ setCustomerEmail(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>

            <div>
              <div className={'cpo-payment-card-modal-back-button '} onClick={()=>{setEnrollTab(0)}}><p>이전</p></div>
              <div className={'cpo-payment-card-modal-enroll-button '+ `${cardName == ""   ?"none":""}`} onClick={()=>{enrollReq()}}><p>등록</p></div>
            </div>
          </>
          :
          <>
            <p className='cpo-payment-card-enroll-modal-title'>카드 정보</p>
            <div className='cpo-payment-card-enroll-input-title'><p>카드 번호</p></div>
            <div className="cpo-payment-card-enroll-modal-input-container">
              <input type="text" placeholder='카드 번호' value={cardNumber} onChange={(e)=>{ setCardNumber(e.target.value)}} onInput={(e)=>{cardNumInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
            
            <div className='cpo-payment-card-enroll-row-2'>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>유효 일자</p></div>
                <div className='cpo-payment-card-enroll-date-input-wrapper'>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='YY' value={cardYY} onChange={(e)=>{ setCardYY(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='MM' value={cardMM} onChange={(e)=>{ setCardMM(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                </div>
              </div>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>사업자등록번호</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='사업자등록번호 10자리' value={customerIdentityNumber} onChange={(e)=>{ setCustomerIdentityNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
            </div>
            <div className='cpo-payment-card-enroll-row-3'>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>카드 비밀번호</p></div>
                <div className='cpo-payment-card-enroll-input-wrapper'>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='' value={partialPassword} onChange={(e)=>{ setPartialPassword(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                  <p>**</p>
                </div>
              </div>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>CVC</p></div>
                <div className='cpo-payment-card-enroll-input-wrapper'>
                  <div className="cpo-payment-card-enroll-modal-input-container">
                    <input type="text" placeholder='' value={cvc} onChange={(e)=>{ setCvc(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="cpo-payment-card-enroll-row-4">
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>카드 명</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='' value={cardName} onChange={(e)=>{ setCardName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>기본 결제카드로 설정</p></div>
                <Toggle on={'예'} off={'아니오'} onOff={defaultCard} setOnOff={setDefaultCard}/>
              </div>
            </div>
            
            <div className='cpo-payment-card-enroll-customer-info-row'>
              <p className='cpo-payment-card-enroll-modal-title'>고객 정보</p>
              <div className='cpo-payment-card-enroll-input-title'><p>이름</p></div>
              <div className="cpo-payment-card-enroll-modal-input-container">
                <input type="text" placeholder='' value={customerName} onChange={(e)=>{ setCustomerName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div className='cpo-payment-card-enroll-input-title'><p>이메일</p></div>
              <div className="cpo-payment-card-enroll-modal-input-container">
                <input type="text" placeholder='' value={customerEmail} onChange={(e)=>{ setCustomerEmail(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>

            <div>
              <div className={'cpo-payment-card-modal-back-button '} onClick={()=>{setEnrollTab(0)}}><p>이전</p></div>
              <div className={'cpo-payment-card-modal-enroll-button '+ `${cardName == ""   ?"none":""}`} onClick={()=>{enrollReq()}}><p>등록</p></div>
            </div>
          </>
        }
        
        
        
      </div>
    </div>
  )
}
export default PaymentCardEnrollModal;