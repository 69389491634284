import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum, setDetailData} from '../../store.js'
import axios from 'axios';
import ChargerDetailTab1 from '../tabs/ChargerDetailTab1.js';
import ChargerDetailTab2 from '../tabs/ChargerDetailTab2.js';
import ChargerDetailTab3 from '../tabs/ChargerDetailTab3.js';

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { getCookie } from '../../util/util.js';


const ChargerDetailModal = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let detail = useSelector((state)=>state.detail);
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  const [clicked, setClicked] = useState("")
  // useEffect(()=>{
    
  //   console.log(props.data);
  //   console.log(props.chasData);
  // },[])
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }
  // /infra/api/ChargerModels

  const [chargerModelList, setChargerModelList] = useState();
  useEffect(()=>{
    getChargerModelList();
  },[])
  const getChargerModelList = async(id)=>{
    await axios.get(`/infra/api/ChargerModels`,{
    })
    .then((res)=>{
      let temp = [...res.data.Results];
      console.log(temp);
      setChargerModelList(temp);
    })
  }
  function RenderTab({idx}){
    return [ <ChargerDetailTab1 setSaveBtnStatus={setSaveBtnStatus} data={props.data} chas={props.chasData} number={props.data?.DisplaySerialNumber} open={props.detailModalOpen}/>, <ChargerDetailTab2 setSaveBtnStatus={setSaveBtnStatus} modelList={chargerModelList} data={props.data} number={props.data?.DisplaySerialNumber} open={props.detailModalOpen}/>, <ChargerDetailTab3 open={props.detailModalOpen} number={props.data?.DisplaySerialNumber}/>][idx]
  }

  const slideEvent = (pre)=>{
    if(pre == -1){
      if(props.clickedCharger == 0) return;
      else props.setClickedCharger(props.clickedCharger-1);
    }
    else{
      console.log(props.clickedCharger);
      if(props.clickedCharger == props.lastIdx) return;
      else props.setClickedCharger(props.clickedCharger+1);
    }
  }

  let save = async()=>{
    console.log(props.data);
    let t1 = JSON.parse(localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1'));
    let t2 = JSON.parse(localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2'));

    
    // setIsItInOperation(temp['isItInOperation']);

    let data ={
      "chargingStationId": props.data?.ChargingStation.ChargingStationId,
      "chargerModelId": t2 ? JSON.parse(t2['ChargerModel']):props.data?.ChargerModel ? props.data?.ChargerModel.Id:null,
      "protocol": t1 ? JSON.parse(t1['Protocol']):props.data?.Protocol,
      "chargePointSerialNumber": t1 ? JSON.parse(t1['ChargePointSerialNumber']):props.data?.ChargePointSerialNumber,
      "chargeBoxSerialNumber": props.data?.ChargeBoxSerialNumber,
      "commonCategory": t1 ? JSON.parse(t1['CommonCategory']):props.data?.CommonCategory,
      "installationDate": t1 ? JSON.parse(t1['InstallationDate']):props.data?.InstallationDate,
      "operationStartDate": t1 ? JSON.parse(t1['OperationStartDate']):props.data?.OperationStartDate,
      "authenticationKey": t1 ? JSON.parse(t1['AuthenticationKey']):props.data?.AuthenticationKey,
      "kepcoCustomerNumber": t1 ? JSON.parse(t1['KEPCOCustomerNumber']):props.data?.KEPCOCustomerNumber,
      "parentCustomerNumber": t1 ? JSON.parse(t1['ParentCustomerNumber']):props.data?.ParentCustomerNumber,
    }


    
    console.log(data);
    await axios.put(`/infra/api/cpouser/v1/Chargers/${props.data?.ChargerId}`,data,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1');
      localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2');
      
      props.getChargerList();
      props.getChargerHubList();
      setSaveBtnStatus(false);
    })
    .catch((err)=>{
      console.log(err);
    })


    // localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1');
    // localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2');
  }

  const [saveBtnStatus, setSaveBtnStatus] = useState(false);
  useEffect(()=>{
    let t1 = localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1');
    let t2 = localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2');
    
    if(t1 || t2){
      setSaveBtnStatus(true);
    }
  },[])

  /*
  InstallationDate
  OperationStartDate
  AuthenticationKey
  KEPCOCustomerNumber
  ParentCustomerNumber
  "status"
  "actionSetName"
  Protocol
   */

  return (
    <div className={props.detailModalOpen ? 'charger-detail-modal-container open': 'charger-detail-modal-container'}>
      <div className='charger-detail-modal-header'>
        <p className='charger-detail-modal-title'>{props.data?.ChargingStation.SerialNumber + "-" +props.data?.DisplaySerialNumber}</p>
        <div className={"station-detail-modal-button-container "}>
          <div onClick={()=>{props.setEnrollModalOn(true)}}><p>등록</p></div>
          <div className='delete'><p>삭제</p></div>
          {/* <div className='excel'><RiFileExcel2Line/><p>엑셀 다운로드</p></div> */}
        </div>
        {/* <p className='charger-detail-modal-desc'>Charger Details</p> */}
      </div>
      <div className="tab-button-container">
        <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx == 0 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(0)}}><p>기본</p></div>
        <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx == 1 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(1)}}><p>모델</p></div>
        <div ref={el=>tabBtnRef.current[2]=el} className={tabIdx == 2 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(2)}}><p>QR코드</p></div>
        <div className={'tab-bar '+'tab'+tabIdx}></div>
      </div>
      <div className="tab-content-container">
        <div className="tab-content">
          {
            <RenderTab idx={tabIdx}/>
          }
        </div>
      </div>
      <div className='charger-detail-modal-left-button-container' onClick={(e)=>{e.stopPropagation(); slideEvent(-1)}}>
        <BsChevronCompactLeft/>
      </div>
      <div className='charger-detail-modal-right-button-container' onClick={(e)=>{e.stopPropagation(); slideEvent(1)}}>
        <BsChevronCompactRight/>
      </div>
      <div className={'charger-detail-modal-save-button-container ' + `${saveBtnStatus ? "":"off"}`} onClick={(e)=>{e.stopPropagation(); e.preventDefault(); save();}}><p>저장하기</p></div>
    </div>
  )
}
export default ChargerDetailModal;