import React, { useRef, useState, useEffect } from 'react';
import { Outlet, RouterProvider,createBrowserRouter, useNavigate } from 'react-router-dom'
import { getCookie, removeCookie, refresh } from '../../util/util.js';

import '../../styles/Default.css';

export const MainTemplate = () => {
  let navigator = useNavigate();
  let refreshToken = getCookie("refreshToken");
  let signedToken = getCookie("signedToken");


  return (
    <div className='main-template-container'>
      <div className="main-template-header">
        <div className="main-template-header-inner">
          <div className="nav-logo-container">
            <img className='nav-logo' alt="" onClick={()=>{}}/>
          </div>
          <div className='nav-sign-in-container'>
            {
              signedToken ?
              <div onClick={()=>{removeCookie("refreshToken");removeCookie("signedToken");}}><p>로그아웃</p></div>
              :<div onClick={()=>{navigator("/login");}}><p>로그인</p></div>
            }
            <div onClick={()=>{navigator("/sign-up");}}><p>회원가입</p></div>
          </div>
        </div>
      </div>
      <section>
        <Outlet/>
      </section>
      <footer>
        <p>Copyright (C) (주)모니트 All right Reserved.</p>
      </footer>
    </div>
  )
}
export default MainTemplate;