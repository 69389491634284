import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate } from "react-router-dom";
import { getCookie } from '../../util/util.js';
import SubscriptionEnrollModal from '../../components/modals/SubscriptionEnrollModal.js';
import SubscriptionProductionCard from '../../components/list/SubscriptionProductionCard.js';
import SubscriptionEnrolledCard from '../../components/list/SubscriptionEnrolledCard.js';

const CpoSubscription = (props) => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");  
  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:7, idx:0}));
  },[])

  // 현재 계정의 구독 리스트 호출
  const [currentSubList, setCurrentSubList] = useState([]);
  // 현재 계정의 구독 가능한 리스트 호출
  const [futureSubList, setFutureSubList] = useState([]);

  // 현재 계정의 구독 리스트 호출
  const getCurrentSubscriptionList = async()=>{
    await axios.get(`/subscription/api/cpoadmin/Subscriptions`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      let temp = [...res.data];
      setCurrentSubList(temp);
    })
    .catch((err)=>{
      console.log(err);
    })
  }
  // 현재 계정의 구독 가능한 리스트 호출
  const getFutureSubscriptionList = async()=>{
    await axios.get(`/subscription/api/cpoadmin/SubscriptionProducts`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      let temp = [...res.data];
      setFutureSubList(temp);
    })
    .catch((err)=>{
      console.log(err);
    })
  }
  
  useEffect(()=>{
    getCurrentSubscriptionList();
    getFutureSubscriptionList();
  },[])

  

  const [flip, setFlip] = useState(false);
  const [modalOn, setModalOn] = useState(false);
  const [subscriptionItem, setSubscriptionItem] = useState(null);

  const [tempData, setTempData] = useState(null);

  return (
    <div className='cpo-system-setting-container'>
        <div className='cpo-subscription-divider'></div>
      {modalOn&&subscriptionItem&&<SubscriptionEnrollModal setOnOff={setModalOn} getCurrentSubscriptionList={getCurrentSubscriptionList} data={subscriptionItem} setCurrentSubList={setCurrentSubList} />}
      <div className="cpo-system-setting-inner">
        <div className="cpo-subscription-page-container">
          {/* {
            currentSubList&&futureSubList&&cardList&&(<div onClick={()=>{console.log(currentSubList, futureSubList, cardList)}}>인냥</div>)
          } */}
          <div className="cpo-current-subscription-section top">
            <title>구독 가능 상품</title>
            <div className="cpo-current-subscription-item-container">
            {
              futureSubList&&futureSubList.map((it, idx)=>{
                return(
                  <>
                    <SubscriptionProductionCard setModalOn={setModalOn} data={it} setSubscriptionItem={setSubscriptionItem} />
                  </>
                )
              })
            }
            </div>
          </div>
          <div className="cpo-current-subscription-section bottom">
            <title>현재 구독 중</title>
            <div className='cpo-enrolled-subscription-item-container'>
            {
              currentSubList&&currentSubList.map((it, idx)=>{
                return(
                  <SubscriptionEnrolledCard data={it}/>
                )
              })
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoSubscription;