import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import Selector2 from '../buttons/Selector2';

export const ChasDuplicationModal = (props) => {
  const [chasSelect, setChasSelect] = useState();
  const [chasActive, setChasActive] = useState();

  const activeReset = ()=>{
    setChasActive(false);
  }
  return (
    <div className="cpo-chas-duplication-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-chas-duplication-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-chas-duplication-modal-header">
          <title>CHAS 복제</title>
        </div>
        <div className='cpo-chas-duplication-modal-content-container'>
          <p>CHAS 선택</p>
          <div>
            <Selector2 name={'name'} dataList={props.data} select={chasSelect} setSelect={setChasSelect} active={chasActive} setActive={setChasActive} activeReset={activeReset}/>
          </div>
        </div>
        <div className='cpo-chas-duplication-modal-content-container'>
          <p>CHAS 이름</p>
          <div className='cpo-chas-duplication-modal-grid-wrapper'>
            <div className='cpo-chas-duplication-modal-input-container'>
              <input type="text" placeholder='CHAS의 이름을 입력해주세요'/>
            </div>
            <div className='cpo-chas-duplication-check-button'>중복확인</div>
          </div>
        </div>
        <div className='cpo-chas-duplication-modal-content-container'>
          <p>설명</p>
          <div className='cpo-chas-duplication-modal-input-container textarea'>
            <textarea type="text" placeholder='설명을 입력해주세요' />
          </div>
        </div>
        
        <div className='cpo-chas-duplication-modal-enroll-button'>추가하기</div>


      </div>
    </div>
  )
}
export default ChasDuplicationModal
