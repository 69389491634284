import {Cookies} from 'react-cookie';
import axios from 'axios';

const cookies = new Cookies();

export const setCookie = (name, value, options) => {
  return cookies.set(name, value, {...options}); 
}

export const getCookie = (name) => {
  return cookies.get(name); 
}
export const removeCookie = (name) => {
  cookies.remove('signedToken', { path: '/' });
}

export const chargerTypeConverter = (type)=>{
  switch (type) {
    case "FAST_CHARGER":
      return '고속';
  
    case "SLOW_CHARGER":
      return '고속';
    // TODO 중속은??????
    default:
      return "-"
  }
}

export const connectorTypeConverter = (type)=>{
  switch (type) {
    case "DC_Chademo":
      return 'DC콤보';
  
    // TODO 디른 타입은??????
    default:
      return type;
  }
}

export const chargerStatusConverter = (status)=>{
  switch (status) {
    case 'NotUsed':
      return '연결해제'
    case 'ReadyForConnection':
      return '연결대기'
    case 'Connected':
      return '연결됨'
    case 'Available':
      return '사용가능'
    case 'Preparing':
      return '충전준비'
    case 'Charging':
      return '충전중'
    case 'SuspendedEVSE':
      return '충전중지'
    case 'SuspendedEV':
      return '충전중지'
    case 'Finishing':
      return '충전종료'
    case 'Reserved':
      return '예약중'
    case 'Unavailable':
      return '사용중지'
    case 'Faulted':
      return '고장'
    case 'Deleted':
      return '삭제'
    default:
      return '연결해제';
  }
}

export const occpConverter = (val)=>{
  return val.slice(5).replaceAll('_',".");
}

export const refresh = async()=>{
  await axios.post('/account/api/NearCpoAuth/refresh-token')
  .then((res)=>{
    console.log(res);
    setCookie('signedToken', res.data.SignedToken, {
      path: '/',
      expires: new Date(res.data.ExpiryTime)
    });
  })
  .catch((err)=>{
    console.log(err);
    window.location.replace('/login');
  })
}
export const signedOut = async()=>{
  let signedToken = getCookie('signedToken');

  axios.post(`/account/api/NearCpoAuth/signout`,{},{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    console.log(res);
    removeCookie();
    localStorage.removeItem('LocalRealName');
    window.location.replace('/');
  })
  .catch((err)=>{
    console.log(err);
  })
}

