import React, {useState, useRef, useEffect} from 'react'
import { Outlet, useNavigate, Routes, Route, Link, Switch, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import NavButton from "../../components/buttons/NavButton.js";
import Sidebar from "../../components/sidebars/Sidebar.js";
import { FiChevronsLeft } from "react-icons/fi";
import {setNum, cpoActions} from '../../store.js'
import { getCookie, refresh, signedOut } from '../../util/util.js';

// CSS
import '../../styles/MainPage.css'
import '../../styles/CpoControl.css'
import '../../styles/CpoFastEnroll.css'
import '../../styles/CpoManagement.css'
import '../../styles/CpoChargerManagement.css'
import '../../styles/CpoCustomerService.css'
import '../../styles/CpoSystemSetting.css'
import '../../styles/CpoAccountManagement.css'
import '../../styles/CpoChargingFeeManagement.css'


const CpoMainPage = ({ children }) => {
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");
  let LocalRealName = localStorage.getItem('LocalRealName');
  const getCHASList = async()=>{
    //TODO cpo id를 계정으로 부터 받아서 쿼리에 넣을 예정
    await axios.get(`/controlhub/api/cpouser/v1/ActionSets`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then(async(res)=>{
      if(res.data.length == 0){
        await axios.post(`/controlhub/api/cpouser/v1/ActionSets`,
        {
          "name": "default",
          "description": "기본"
        },{
          headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        
      })
    .catch((err)=>{
      
    })
      }
    })
    .catch((err)=>{
      
    })
    
  }
  useEffect(()=>{
    getCHASList();
  },[])

  let page = useSelector((state) => state.page );
  let navigator = useNavigate();

  // 대분류 (세부 아이템 있는 경우)
  const [category, setCategory] = useState("")
  // 타이틀
  const [title, setTitle] = useState("대시보드")
  
  // 페이지 변경 시 타이틀 설정
  useEffect(()=>{
    if(page.num.id == -1)return;
    const found = menuItems.find((el) => el.id == page.num.id);
    if(found.items){
      setCategory(found.name);
      setTitle(found.items[page.num.idx]);
    }
    else{
      setCategory("");
      setTitle(found.name);
    }
  },[page])
  
  // 페이지 변경 시 라우팅
  useEffect(()=>{
    if(page.num.id == -1)return;
    renderSection()
  }
  ,[page.num])

  // 라우팅 function
  const renderSection = ()=>{
    switch (page.num.id) {
      case 0: //대시보드
        navigator('/cpo');
        break
      
      case 1: //빠른등록
        navigator('/cpo/fast-enroll');
            break
      
      case 2: //충전기 운용제어
        switch (page.num.idx) {
          case 0: //충전기 제어
            navigator('/cpo/charger-control');
            break
          
          case 1: //충전요금관리
            navigator('/cpo/charging-fee-management');
            break
          
          default:
            navigator('/cpo/charger-control');
            break
        }
      break

      case 3: //충전기로그조회
        navigator('/cpo/log-report');
            break

      case 4: //충전결제이력
        navigator('/cpo/pay-report');
            break

      case 5: //충전인프라관리
        switch (page.num.idx) {
          case 0: //충전소관리
            navigator('/cpo/station-management');
            break
          
          case 1: //충전기 관리
            navigator('/cpo/charger-management');
            break
          
          case 2: //설치업체 관리
            navigator('/cpo/install-management');
            break
          
          default:
            navigator('/cpo/charger-management');
            break
        }
      break
      
      case 6: // 고객서비스관리
        switch (page.num.idx) {
          case 0: // FAQ
            navigator('/cpo/faq');
            break
          
          case 1: // 1:1 질문
            navigator('/cpo/qna');
            break
          
          case 2: // 공지사항
            if(page.num.detail)break;
            if(page.num.edit)break;

            navigator('/cpo/notice');
            break
          
          case 3: // 고장신고
            navigator('/cpo/breakdown');
            break
          
          default:
            navigator('/cpo/qna');
            break
        }
      break
      
      case 7: // 시스템설정
        switch (page.num.idx) {
          case 0: // 구독
            navigator('/cpo/subscription');
            break
          
          case 1: // CHAS
            if(page.num.detail)break;
            navigator('/cpo/chas');
            break
          
          default:
            navigator('/cpo/subscription');
            break
        }
      break

      case 8: // 계정 정보 관리
        switch (page.num.idx) {
          case 0: // 결제수단
            navigator('/cpo/payment-method');
            break
          
          case 1: // 법인사업자인증
            navigator('/cpo/member-info-update');
            break
          
          case 2: // 회원정보 변경
            navigator('/cpo/member-info-update');
            break
          
          case 3: // 회원탈퇴
            navigator('/cpo/member-info-update');
            break
          
          default:
            navigator('/cpo/payment-method');
            break
        }
      break

      case 9:
        navigator('/cpo/group-management');
            break
      
      default:
        navigator("/cpo")
        break
    }

  };

  // nav 오픈 여부 state
  const [navOff, setNavOff] = useState("");

  // menu 아이템 리스트
  const menuItems = [
    {
      name:'대시보드',
      id: 0,
    },
    {
      name:'빠른 등록',
      id: 1,
    },
    {
      title : '제어'
    },
    {
      name:'충전기운용 제어',
      id: 2,
      items:['충전기 제어','충전요금 관리']
    },
    {
      name:'충전기로그 조회',
      id: 3,
    },
    {
      name:'충전결제 이력',
      id: 4,
    },
    {
      title : '관리'
    },
    {
      name:'충전인프라 관리',
      id: 5,
      items:['충전소 관리','충전기 관리','설치업체 관리']
    },
    {
      name:'고객서비스 관리',
      id: 6,
      items:['자주묻는 질문(FAQ)','1:1 질문','공지사항','고장신고']
    },
    {
      name:'시스템 설정',
      id: 7,
      items:['구독','CHAS']
    },
    {
      title : '계정 및 조직'
    },
    {
      name:'내 정보 관리',
      id:8,
      items:['결제수단','법인사업자인증','회원정보변경','회원탈퇴']
    },
    {
      name:'조직 관리',
      id:9,
    },
  ];

  // nav버튼 Ref array
  let navButtonsRef = useRef([]);
  // navSub버튼 Ref array
  let navSubButtonsRef = useRef({});


  return (
    <>
      <div className="main-page-container">
        <div className={'main-nav-controller '+navOff} onClick={()=>{navOff === "" ? setNavOff("off"):setNavOff("")}}>
          <FiChevronsLeft/>
        </div>
        <nav className={'main-nav '+navOff}>
          <div className="nav-logo-container">
            <img className='nav-logo' alt="" onClick={()=>{
              navigator("/cpo"); dispatch(setNum({id:0})); 
            }}/>
          </div>
          <div className="nav-button-list-container">
            <Sidebar navButtonsRef={navButtonsRef} navSubButtonsRef={navSubButtonsRef} menuItems={menuItems}/>
          </div>
        </nav>
        <div className={'main-nav-back '+navOff}></div>
        <div className={navOff !== 'off' ? 'main-view-container' : 'main-view-container navOff'}>
          <header className='main-view-header'>
            <div className="main-view-title-container">
              <h3>{category}</h3>
              {
                title !== "CHAS"?
                <h2>{title}</h2>
                :<h2>CHAS (Control Hub Action Set)</h2>
              }
            </div>
            <div className='main-nav-user-info-container'>
              <p><strong>{`${LocalRealName}`}</strong>{' 님'}</p>
              <p onClick={()=>{signedOut();}}>로그아웃</p>
            </div>
          </header>
          <section>
            <Outlet />
          </section>
        </div>
      </div>
      <footer>
        <p>Copyright (C) (주)모니트 All right Reserved.</p>
      </footer>
    </>
  )
}

export default CpoMainPage;
