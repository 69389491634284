import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import Toggle from '../buttons/Toggle.js';
import Selector from '../buttons/Selector.js';
import Selector2 from '../buttons/Selector2.js';
import { FaWonSign } from "react-icons/fa6";
import { MdElectricCar } from "react-icons/md";
import {chargerTypeConverter, connectorTypeConverter} from "../../util/util.js"

const ChargerDetailTab2 = (props) => {
  // 선택자 클릭 여부
  const [ChargerModel, setChargerModel] = useState('');
  const [ChargerModelVal, setChargerModelVal] = useState('');
  const [ChargerModelActive, setChargerModelActive] = useState(null);
  
  // 선택자 active reset
  const activeReset = ()=>{
    setChargerModelActive(false);
  }

  const [modelInfo, setModelInfo] = useState(null);
  
  useEffect(()=>{
    if(!ChargerModel)return;
    setModelInfo(props.modelList.filter(it=>it.Id == ChargerModel)[0]);
  },[ChargerModel])

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.number}`+'_ChargerDetailTab2');
    if(temp){
      temp = JSON.parse(temp);
      setChargerModel(JSON.parse(temp['ChargerModel']));
      setChargerModelVal(props.modelList.filter(it=>it.Id == JSON.parse(temp['ChargerModel']))[0]);
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    console.log(props.data.ChargerModel);
    if(!props.data.ChargerModel)return;
    let temp = localStorage.getItem(`${props.number}`+'_ChargerDetailTab2');
    if(!temp){
      setChargerModel(props.data.ChargerModel?.Id);
      setChargerModelVal(props.modelList.filter(it=>it?.Id == props.data.ChargerModel?.Id)[0]);
    }
  },[])

  useEffect(()=>{
    if(!props.open)return;
    if(ChargerModel=='')return;
    if(ChargerModel==props.data.ChargerModel?.Id)return;
    localStorage.setItem(`${props.number}`+'_ChargerDetailTab2', JSON.stringify( {ChargerModel:JSON.stringify(ChargerModel)}))
    props.setSaveBtnStatus(true);
  },[ChargerModel])

  useEffect(()=>{
    if(!ChargerModelVal)return;
    setChargerModel(ChargerModelVal.Id);
  },[ChargerModelVal])
  

  return (
    <div className='charger-detail-tab tab2'>
      <div>
        <div className='charger-detail-tab-selector-container'>
          <p className='charger-input-title' onClick={()=>{console.log(ChargerModel)}}>충전기 모델</p>
          <Selector2 append={true} name={"Name"} dataList={props.modelList} select={ChargerModelVal} setSelect={setChargerModelVal} active={ChargerModelActive} setActive={setChargerModelActive} activeReset={activeReset}/>
        </div>
      </div>
      <div>
        <p className='charger-input-title'>기기 정보</p>
        <div className='charger-model-info-wrapper'>

          <div className="charger-detail-info-input-wrapper">
            <p>충전기 종류</p>
            <div className="charger-detail-info-input-container">
              <input type="text" placeholder='' value={chargerTypeConverter(modelInfo?.ChargerType)} onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
            </div>
          </div>
          <div className="charger-detail-info-input-wrapper">
            <p>커넥터 타입</p>
            <div className="charger-detail-info-input-container">
            <input type="text" placeholder='' value={connectorTypeConverter(modelInfo?.ConnectorType)} onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
            </div>
          </div>
          
          <div className="charger-detail-info-input-wrapper chargerTab2">
            <p>속도</p>
            <div className="charger-detail-info-input-container">
            <input type="text" placeholder='' value={modelInfo?.ChargingSpeed}  onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
            </div>
          </div>
          <div className="charger-detail-info-input-wrapper chargerTab2">
            <p>출력</p>
            <div className="charger-detail-info-input-container">
            <input type="text" placeholder='' value={modelInfo?.ChargingPower}  onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
            </div>
          </div>
          <div className="charger-detail-info-input-wrapper chargerTab2">
            <p>전압</p>
            <div className="charger-detail-info-input-container">
            <input type="text" placeholder='' value={modelInfo?.Voltage}  onClick={(e)=>{e.stopPropagation(); }} readOnly/>
            </div>
          </div>
          <div className="charger-detail-info-input-wrapper chargerTab2">
            <p>전류</p>
            <div className="charger-detail-info-input-container">
            <input type="text" placeholder='' value={modelInfo?.ElectricCurrent} onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
            </div>
          </div>

          
        </div>
      </div>
      <div>
        <p className='charger-input-title'>상세 정보</p>
        <div className="charger-detail-info-input-wrapper">
          <p>등록년월</p>
          <div className="charger-detail-info-input-container">
          <input type="text" placeholder='' value={modelInfo?.ActualRegistrationDate}  onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
          </div>
        </div>
        <div className="charger-detail-info-input-wrapper">
          <p>설명</p>
          <div className="charger-detail-info-input-container">
          <input type="text" placeholder='' value={modelInfo?.Remarks}  onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
          </div>
        </div>
        
        <div className="charger-detail-info-input-wrapper">
          <p>상세스펙</p>
          <div className="charger-detail-info-input-container">
          <input type="text" placeholder='' value={modelInfo?.DetailedSpec}  onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
          </div>
        </div>
        <div className="charger-detail-info-input-wrapper">
          <p>사용여부</p>
          <div className="charger-detail-info-input-container">
          <input type="text" placeholder='' value={modelInfo?.IsUsed ?"사용": modelInfo?.IsUsed==undefined?"":"미사용"}  onClick={(e)=>{e.stopPropagation(); }} readOnly/> 
          </div>
        </div>

      </div>
    </div>
  )
}
export default ChargerDetailTab2;
