import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';
import { CgScrollV } from "react-icons/cg";
import { FaPlusCircle } from "react-icons/fa";
import ChargingFeeSettingModal from '../../components/modals/ChargingFeeSettingModal.js';
import Selector2 from '../../components/buttons/Selector2.js';

const CpoChargingFeeManagement = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  useEffect(()=>{
    dispatch(setNum({id:2, idx:1}));
  },[])


  let tabList = [
    {id:"",name:"충전기 설치"},
    {id:"",name:"충전기 이용"},
    {id:"",name:"충전기 A/S"},
    {id:"",name:"회원계정"},
    {id:"",name:"회원카드"},
    {id:"",name:"요금/결제"},
    {id:"",name:"앱 이용방법"},
    {id:"",name:"기타"},
  ];
  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }
  

  // station data 
  const stationData = [
    {
      DisplayId:'MOT-1',
      ChargingStationName:'충전소1',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1,2]
    },
    {
      DisplayId:'MOT-2',
      ChargingStationName:'충전소2',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'미운영',
      ChargerIds:[]
    },
    {
      DisplayId:'MOT-3',
      ChargingStationName:'충전소3',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1]
    },
    {
      DisplayId:'MOT-4',
      ChargingStationName:'충전소4',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'미운영',
      ChargerIds:[1,2]
    },
    {
      DisplayId:'MOT-5',
      ChargingStationName:'충전소5',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영',
      ChargerIds:[1,2,3,4,5,6,7,8]
    },
    {
      DisplayId:'MOT-6',
      ChargingStationName:'충전소6',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1,2]
    },
    {
      DisplayId:'MOT-7',
      ChargingStationName:'충전소7',
      Address:'세종특별자치시 조치원읍 군청로 95',
      DetailedAddress:'지하 1층',
      OpStatus:'운영중',
      ChargerIds:[1,2,3]
    },
  ]


  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  useEffect(()=>{
    getDefaultRate();
    getChargerRateList();
    getStationList();
  },[])


  // cpo가 소유한 station GET
  const getStationList = async()=>{  
    await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      let temp = [...res.data.Results];
      setStationInfo(temp);
    })
  }

  // chargerHub 정보 API GET
  const getChargerHubList = async()=>{
    let temp = [];
    for await(const it of stationInfo){
      await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
    }
    setChargerHubInfo(temp);
    setStation(stationInfo[0])
  }

  // charger 정보 API GET
  const getChargerList = async()=>{
    let temp = [];
    for await(const it of stationInfo){
      await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
    }
    console.log(temp);
    setChargerInfo(temp);
  }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    getChargerHubList();
    getChargerList();
  }, [stationInfo])

  // 클릭한 station idx
  useEffect(()=>{
    if(clickedCharger !== null) setClickedCharger(0);
  },[tabIdx])

  const chargerRef = useRef([]);

  const clickEvent = (idx)=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    if(clickedCharger !== idx){
      setClickedCharger(idx)
    }
    else{
      setClickedCharger(null)
    } 
  }

  // 클릭한 station의 idx state
  const [clickedCharger, setClickedCharger] = useState(null);

  // check한 charger list
  const [checkChargerList, setCheckChargerList] = useState([]);

  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);

  useEffect(()=>{
    if(checked){
      let temp = new Array(stationData.length).fill(0).map((_, i) => i)
      setCheckChargerList(temp);
    }
    else{
      setCheckChargerList([]);
    }
  },[checked])



  const[defaultRate, setDefaultRate] = useState({"membershipRate": null,"nonMembershipRate": null});
  const[chargerRateList, setChargerRateList] = useState([]);

  
  // 기본 설정 단가 get
  let getDefaultRate = async()=>{
    await axios.get(`/chargerate/api/cpouser/DefaultRate`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setDefaultRate(res.data);
    })
  }
  
  let getChargerRateList = async()=>{
    await axios.get(`/chargerate/api/cpouser/Chargers`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setChargerRateList(res.data);
    })
  }
  const regionList = [
    {name: 'A(서울, 인천, 경기)'},
    {name: 'B(충청, 전라)'},
    {name: 'C(강원, 경상)'},
    {name: 'D(제주)'},
    // {name: '미분류'},
  ];

  const [Region, setRegion] = useState('');
  const [RegionActive, setRegionActive] = useState(null);
  const [Station, setStation] = useState('');
  const [StationActive, setStationActive] = useState(null);
  const activeReset = ()=>{
    setRegionActive(false);
    setStationActive(false);
  }


  const [modalOff, setModalOff] = useState(false);
  const func = async()=>{
    await axios.post('/account/api/NearCpoAuth/refresh-token',{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
    })
    .catch((err)=>{
      console.log(err);
    })
  }
  return (
    <div className="cpo-charging-fee-management-page-container">
        {modalOff && <ChargingFeeSettingModal getChargerRateList={getChargerRateList} chargerRateList={chargerRateList} stationInfo={stationInfo} chargerInfo={chargerInfo} chargerHubInfo={chargerHubInfo} setOnOff={setModalOff} defaultRate={defaultRate} setDefaultRate={setDefaultRate}/>}
      <div className="cpo-charging-fee-management-inner">
        <div className="cpo-charging-fee-management-container">
          <div className="cpo-charging-fee-management-header">
            <p onClick={()=>{func()}}>
              기본 충전 요금
            </p>
            <div className='cpo-charging-fee-management-add-button' onClick={()=>{setModalOff(true)}}><p>충전 요금 설정</p></div>
          </div>
          <div className='cpo-charging-fee-default-card-container'>
            <div className='cpo-charging-fee-default-card'>
              <p className='cpo-charging-fee-default-card-title'>멤버쉽</p>
              <div className="cpo-charging-fee-default-card-content-wrapper">
                <p className="cpo-charging-fee-default-card-content">{defaultRate.membershipRate?defaultRate.membershipRate:"-"}</p>
              </div>
              <div className="cpo-charging-fee-default-card-sign-wrapper">
                <p>₩</p>
              </div>
            </div>
            <div className='cpo-charging-fee-default-card'>
              <p className='cpo-charging-fee-default-card-title'>비멤버쉽</p>
              <div className="cpo-charging-fee-default-card-content-wrapper">
                <p className="cpo-charging-fee-default-card-content">{defaultRate.nonMembershipRate?defaultRate.nonMembershipRate:"-"}</p>
              </div>
              <div className="cpo-charging-fee-default-card-sign-wrapper">
                <p>₩</p>
              </div>
            </div>
          </div>
          <div className='cpo-faq-tab-container'>
            <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx == 0 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(0)}}><p>{"권역-충전소"}</p></div>
            <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx == 1 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(1)}}><p>{"그룹"}</p></div>
            <div className={'faq-tab-bar '+'tab'+tabIdx}></div>
          </div>
          {
            tabIdx == 0 ?
            <>

              {/* <div className='cpo-charging-fee-category-container'>
                <div className="cpo-charging-fee-category-card region">
                  <CgScrollV/>
                  <p className='cpo-charging-fee-category-card-title'>권역</p>
                  <div className='cpo-charging-fee-category-card-content-wrapper region'>
                    <p>{"A (서울, 경기, 인천)"}</p>
                  </div>
                </div>
                <div className="cpo-charging-fee-category-card station">
                  <CgScrollV/>
                  <p className='cpo-charging-fee-category-card-title'>충전소</p>
                  <div className='cpo-charging-fee-category-card-content-wrapper'>
                    <p>벽산블루밍아파트B단지입주자대표회의 지하2층 113동114동사이 10번기둥</p>
                  </div>
                </div>
              </div> */}
              <p className='cpo-charging-fee-category-title'>권역</p>
              <div className="cpo-charging-fee-setting-modal-function-column1">
                <Selector2 name={"name"} dataList={regionList} select={Region} setSelect={setRegion} active={RegionActive} setActive={setRegionActive} activeReset={activeReset}/>
              </div>
              <p className='cpo-charging-fee-category-title'>충전소</p>
              <div className="cpo-charging-fee-setting-modal-function-column1">
                <Selector2 name={"ChargingStationName"} dataList={stationInfo} select={Station} setSelect={setStation} active={StationActive} setActive={setStationActive} activeReset={activeReset}/>
              </div>
              <div className={'cpo-charging-fee-management-charger-list-container' }>
                <div className="cpo-charging-fee-management-charger-list">
                  <div className='cpo-charging-fee-management-charger-list-wrapper'>
                    <div className="cpo-charging-fee-management-charger-list-column">
                      <div className='cpo-charging-fee-management-charger-list-column-item'><p>권역</p></div>
                      <div className='cpo-charging-fee-management-charger-list-column-item'><p>충전소명</p></div>
                      <div className='cpo-charging-fee-management-charger-list-column-item'><p>식별번호</p></div>
                      <div className='cpo-charging-fee-management-charger-list-column-item'><p>운영여부</p></div>
                      <div className='cpo-charging-fee-management-charger-list-column-item'><p>멤버쉽 단가</p></div>
                      <div className='cpo-charging-fee-management-charger-list-column-item'><p>비멤버쉽 단가</p></div>
                    </div>
                    <div className='cpo-charging-fee-management-list-content'>
                      {
                        chargerInfo&&(chargerInfo[stationInfo.indexOf(Station)]?.Results.map((ia,idx)=>{
                          return(
                          <div className={"cpo-charging-fee-management-list-content-item "} ref={(el)=>{chargerRef.current[0]=el}}>
                            <div><p>{Station?.RegionName}</p></div>
                            <div><p>{ia?.ChargingStation?.ChargingStationName ? ia?.ChargingStation?.ChargingStationName : "-"}</p></div>
                            <div><p>{ia?.DisplaySerialNumber ? ia?.DisplaySerialNumber : "-"}</p></div>
                            <div><p style={{color:'#aaa',fontWeight:"800"}}>{props.chargerHubInfo?.Used ? 'Y':'N'}</p></div>
                            <div><p>{chargerRateList.filter(it=> it.chargerId == ia.ChargerId)[0].memberRate}</p></div>
                            <div><p>{chargerRateList.filter(it=> it.chargerId == ia.ChargerId)[0].nonMemberRate}</p></div>
                          </div>
                          )
                        }))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="cpo-charging-fee-group-tab-container"></div>
            </>
          }
        </div>
        
      </div>
    </div>
  )
}
export default CpoChargingFeeManagement;