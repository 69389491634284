import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { GrConnectivity } from "react-icons/gr";
import { RiOutlet2Line } from "react-icons/ri";
import { FaBoltLightning, FaArrowRotateLeft } from "react-icons/fa6";
import { TbChevronCompactRight } from "react-icons/tb";
import { GrTest, GrUpgrade } from "react-icons/gr";
import { TbReportAnalytics } from "react-icons/tb";
import { RiDeleteBin2Line } from "react-icons/ri";
import { FaStop } from "react-icons/fa";

import {chargerStatusConverter, chargerTypeConverter, connectorTypeConverter} from "../../util/util.js";
import { getCookie } from '../../util/util.js';

const ChargerCard = (props) => {
  const [flip, setFlip] = useState(false);
  const [chargerStatus, setChargerStatus] = useState("");
  let signedToken = getCookie("signedToken");
  
  useEffect(()=>{
    switch (props.controlhubData?.status) {
      case 'NotUsed':setChargerStatus(""); break;
      case 'ReadyForConnection':setChargerStatus("ready"); break;
      case 'Connected':setChargerStatus("connected"); break;
      case 'Available':setChargerStatus("available"); break;
      case 'Preparing':setChargerStatus("preparing"); break;
      case 'Charging':setChargerStatus("charging"); break;
      case 'SuspendedEVSE':setChargerStatus("pause"); break;
      case 'SuspendedEV':setChargerStatus("pause"); break;
      case 'Finishing':setChargerStatus("finishing"); break;
      case 'Reserved':setChargerStatus("reserved"); break;
      case 'Unavailable':setChargerStatus("unavailable"); break;
      case 'Faulted':setChargerStatus("faulted"); break;
      case 'Deleted':setChargerStatus(""); break;
      default:setChargerStatus("");break;
    }
  },[props.controlhubData?.status])



  const chargerStartStop = async(idx)=>{
    {
      idx == 0?
      await axios.put(`/controlhub/api/cpouser/v1/Chargers/${props.data.ChargerId}/Used`,
      {
        "use": false
      }, 
      {
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      }
      ).then((res)=>{
        props.getChargerHubList();
        props.getChargerList();
      })
      :
      await axios.put(`/controlhub/api/cpouser/v1/Chargers/${props.data.ChargerId}/Used`,
      {
        "use": true
      }, 
      {
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      }
      ).then((res)=>{
        props.getChargerHubList();
        props.getChargerList();
      })
    }
    
  }


  // ref={(el)=>{chargerRef.current[idx]=el}}
  return (
    <div className={"cpo-charger-card-container " + `${flip?"flip":""}`} onClick={()=>{ setFlip(!flip) }}>
      <div className='cpo-charger-card-flip-btn'><TbChevronCompactRight className='cpo-charger-card-flip-icon'/></div>
      <div className='cpo-charger-item-container front'>
        <div className="cpo-charger-status-container">
          {/* 충전소일련번호 API 업데이트 시 적용 */}
          <p className='cpo-charger-id'>{"KAS"+"_"+props.data.DisplaySerialNumber}</p>
          <div className={'cpo-charger-status '+`${chargerStatus}`}>
            <p className={'cpo-charger-status-content '}>{chargerStatusConverter(props.controlhubData?.status)}</p>
          </div>
        </div>
        <div className="cpo-charger-type-container">
          <div className="cpo-charger-type-title">
            <GrConnectivity/><p>충전기 타입</p>
          </div>
          <p className='cpo-charger-type'>
            {props.data.ChargerModel ? chargerTypeConverter(props.data.ChargerModel?.ChargerType)  : '-'}
          </p>
        </div>
        <div className="cpo-charger-connector-type-container">
          <div className="cpo-charger-connector-type-title">
            <RiOutlet2Line/><p>커넥터 타입</p>
          </div>
          <p className='cpo-charger-connector-type'>
            {props.data.ChargerModel ? connectorTypeConverter(props.data.ChargerModel?.ConnectorType) : '-'}
          </p>
        </div>
        <div className="cpo-charger-btn-list-container">

          <div className='cpo-charger-onOff-btn-container' onClick={(e)=>{e.stopPropagation(); console.log(props.controlhubData); 
            props.controlhubData?.used?chargerStartStop(0):chargerStartStop(1)
          }}>
            <p>
              {props.controlhubData?.used?"운용중지":"운용시작"}
            </p>
            <div className='cpo-charger-onOff-btn'>
              <div className="cpo-charger-onOff-btn-icon">
                {
                  props.controlhubData?.used?
                  <FaStop/>
                  :
                  <FaBoltLightning/>
                }
              </div>
            </div>
          </div>

          <div className='cpo-charger-reset-btn-container' onClick={(e)=>{e.stopPropagation();}}>
            <p>재시동</p>
            <div className='cpo-charger-onOff-btn'>
              <div className="cpo-charger-onOff-btn-icon">
                <FaArrowRotateLeft/>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='cpo-charger-item-container back'>
        <div className="cpo-charger-detail-container">
          <div>
              <p>제조사명</p>
              <p>{props.data.ChargerModel? props.data.ChargerModel?.Manufacturer.Company.Name : "-"}</p>
          </div>
          <div><p>actionset</p><p>{props.controlhubData?.actionSetName ? props.controlhubData?.actionSetName :"-"}</p></div>
          <div><p>출력속도</p><p>{props.data.ChargerModel? props.data.ChargerModel?.ChargingSpeed : '-'}</p></div>
          <div><p>출력</p><p>{props.data.ChargerModel? props.data.ChargerModel?.ChargingPower : '-'}</p></div>
          <div><p>전압</p><p>{props.data.ChargerModel? props.data.ChargerModel?.Voltage : '-'}</p></div>
          <div><p>전류</p><p>{props.data.ChargerModel? props.data.ChargerModel?.ElectricCurrent : '-'}</p></div>
        </div>
        <div className="cpo-charger-four-btn-container" onClick={(e)=>{e.stopPropagation();}}>
          <div className='cpo-charger-back-btn-container'>
            <p>연결 테스트</p>
            <div><div><GrTest /></div></div></div>
          <div className='cpo-charger-back-btn-container'>
            <p>펌웨어 업데이트</p>
            <div><div><GrUpgrade /></div></div></div>
          <div className='cpo-charger-back-btn-container'>
            <p>로그 히스토리</p>
            <div><div><TbReportAnalytics /></div></div>
          </div>
          <div className='cpo-charger-back-btn-container delete'>
            <p>삭제</p>
            <div><div><RiDeleteBin2Line /></div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChargerCard;