import './App.css';
import { Suspense, lazy } from "react";
import React, { useRef, useState, useEffect } from 'react';

import Lottie from "react-lottie";

import MainTemplate from './pages/Default/MainTemplate.js';
import Login from './pages/Default/Login.js';
import SimpleLogin from './pages/Default/SimpleLogin.js';
import SignUp from './pages/Default/SingUp.js';
import AccountVerification from './pages/Default/AccountVerification.js';

import CpoDashboard from './pages/CPO/CpoDashboard.js';
import CpoFastEnroll from './pages/CPO/CpoFastEnroll.js';
import CpoLogReport from './pages/CPO/CpoLogReport.js';
import CpoPayReport from './pages/CPO/CpoPayReport.js';
import CpoStationManagement from './pages/CPO/CpoStationManagement.js';
import CpoChargerManagement from './pages/CPO/CpoChargerManagement.js';
import CpoInstallManagement from './pages/CPO/CpoInstallManagement.js';
import CpoChargerControl from './pages/CPO/CpoChargerControl.js';
import CpoChargingFeeManagement from './pages/CPO/CpoChargingFeeManagement.js';
import CpoChargerInfo from './pages/CPO/CpoChargerInfo.js';
import CpoFAQ from './pages/CPO/CpoFAQ.js';
import CpoQna from './pages/CPO/CpoQna.js';
import CpoNotice from './pages/CPO/CpoNotice.js';
import CpoBreakdown from './pages/CPO/CpoBreakdown.js';
import CpoSubscription from './pages/CPO/CpoSubscription.js';
import CpoCHAS from './pages/CPO/CpoCHAS.js';
import CpoChasEdit from './pages/CPO/CpoChasEdit.js';
import CpoChasEdit2 from './pages/CPO/CpoChasEdit2.js';
import CpoPaymentMethod from './pages/CPO/CpoPaymentMethod.js';
import CpoMemberInfoUpdate from './pages/CPO/CpoMemberInfoUpdate.js';
import CpoGroupManagement from './pages/CPO/CpoGroupManagement.js';
import CpoNoticeDetail from './pages/CPO/CpoNoticeDetail.js';
import CpoNoticeEdit from './pages/CPO/CpoNoticeEdit.js';


import { Outlet, RouterProvider,createBrowserRouter, useNavigate } from 'react-router-dom'
import CpoMainPage from './pages/CPO/CpoMainPage';
import { NavermapsProvider } from 'react-naver-maps';

import animationSplash from './lotties/boltAnimation.json'
import animationSplash2 from './lotties/404Animation.json'

function App() {
  const adjustZoom = () => {
    const width = window.screen.width * window.devicePixelRatio;
    const height = window.screen.height * window.devicePixelRatio;
    const isQHD = (width === 2560 && height === 1440) || (width === 1440 && height === 2560); // QHD 감지

    if (isQHD) {
      document.body.style.zoom = '1.1'; // 120%로 확대
    } else {
      document.body.style.zoom = '1'; // 기본값으로 설정
    }
  };

  useEffect(() => {
    // adjustZoom();
    // window.addEventListener('resize', adjustZoom);
    // return () => {
    //   window.removeEventListener('resize', adjustZoom);
    // };
  }, []);
  return (
    <NavermapsProvider ncpClientId='f88olicqny'>
      <div className="App">
        <RouterProvider router={router} />
          {/* <CpoMainPage/> */}
        
      </div>
    </NavermapsProvider>
  );
}

export default App;

const router = createBrowserRouter([
  {
    element: (
      <div>
        <Outlet />
      </div>
    ),
    children: [
      {
        path: "cpo",
        element: (
          <Suspense fallback={Loading}><CpoMainPage></CpoMainPage></Suspense>
        ),
        children: [
          {path: "",element: <CpoDashboard/>},

          {path:'/cpo/fast-enroll' ,element:<CpoFastEnroll/>},
          
          {path:'/cpo/log-report' ,element:<CpoLogReport/>},
          {path:'/cpo/pay-report' ,element:<CpoPayReport/>},
          
          {path:'/cpo/charger-control' ,element:<CpoChargerControl/>},
          {path:'/cpo/charging-fee-management' ,element:<CpoChargingFeeManagement/>},

          {path:'/cpo/station-management' ,element:<CpoStationManagement/>},
          {path:'/cpo/charger-management' ,element:<CpoChargerManagement/>},
          {path:'/cpo/install-management' ,element:<CpoInstallManagement/>},

          {path:'/cpo/faq' ,element:<CpoFAQ/>},
          {path:'/cpo/qna' ,element:<CpoQna/>},
          {path:'/cpo/notice' ,element:<CpoNotice/>},
          {path:"/cpo/notice/detail/:id", element:<CpoNoticeDetail />},
          {path:"/cpo/notice/edit", element:<CpoNoticeEdit />},
          {path:'/cpo/breakdown' ,element:<CpoBreakdown/>},

          {path:'/cpo/subscription' ,element:<CpoSubscription/>},
          {path:'/cpo/chas' ,element:<CpoCHAS/>},
          {path:'/cpo/chas/edit/normal' ,element:<CpoChasEdit/>},
          {path:'/cpo/chas/edit/data-transfer' ,element:<CpoChasEdit2/>},

          {path:'/cpo/payment-method' ,element:<CpoPaymentMethod/>},
          {path:'/cpo/member-info-update' ,element:<CpoMemberInfoUpdate/>},
          
          {path:'/cpo/group-management' ,element:<CpoGroupManagement/>},
        ],
      },
      {
        path: "/",
        element:(
          <>
            <MainTemplate></MainTemplate>
          </>
        ),
        children:[
          {path: "",element:<></>},
          {path: "login",element:<Login/>},
          {path: "simple-login",element:<SimpleLogin/>},
          {path: "sign-up",element:<SignUp/>},
          {path: "email-verification",element:<AccountVerification/>},
        ],
      },
      {
        path: "customer",
        element:(
          <div>커스토머</div>
        ),
      },
      {
        path: "*",
        element:(
          <Animation404/>
        ),
      },
    ],
  },
]);

function Loading() {
    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationSplash,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };	
  return (
    <div class="loading-container">
      <div className='loader'>
        <Lottie options={defaultOptions} />
      </div>
    </div>
  )
}
function Animation404() {
  let navigator = useNavigate();
    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationSplash2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };	
  return (
    <div class="loading-container">
      <div className='loader'>
        <Lottie options={defaultOptions} />
      </div>
      <h1>페이지를 찾을 수 없습니다.</h1>
      <div className='goToMain' onClick={()=>{navigator(-1)}}><p>뒤로가기</p></div>
    </div>
  )
}