import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate, useLocation } from "react-router-dom";

import { getCookie, refresh } from '../../util/util.js';
import Selector2 from '../../components/buttons/Selector2.js';

import { FaRegQuestionCircle } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { FaChevronRight } from "react-icons/fa6";
import { MdSearch } from "react-icons/md";

import SearchSelector from '../../components/buttons/SearchSelector.js';

export const CpoChasEdit2 = (props) => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");
  const location = useLocation();
  const data = location.state.data;
  const idData = location.state.idData.filter(it=>it.startsWith("DATA_TRANSFER_REQ_"));
  const c = location.state.idData;
  useEffect(()=>{
    dispatch(setNum({id:7, idx:1, detail:true}));
    console.log(idData)
  },[])

  const messageTypeList=[
    {name:"AUTHORIZE_REQ"},
    {name:"START_TRANSACTION_REQ"},
    {name:"STOP_TRANSACTION_REQ"},
    {name:"REMOTE_START_TRANSACTION_REQ"},
    {name:"REMOTE_STOP_TRANSACTION_REQ"},
  ]
  // props.idData.

  const [actionSelect, setActionSelect] = useState();
  const [actionActive, setActionActive] = useState();
  const activeReset = async()=>{
    setActionActive(false);
  }

  const [chasDataModalOn, setChasDataModalOn] = useState(false);


  // /api/cpouser/v1/ActionSets/{data?.actionSetId}/AUTHORIZE_REQ --메세지 추가

  // /controlhub/api/ControlHubActions --액션들 불러오기

  const [actionList, setActionList] = useState();
  
  const getActions = async()=>{
    axios.get('/controlhub/api/ControlHubActions',{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      // let temp = res.data.filter(it=>)
      setActionList(res.data);
    })
  }



  useEffect(()=>{
    console.log(data);
    getActions();
  },[])
  
  const [labelList, setLabelList] = useState();

  useEffect(()=>{
    if(idData){
      let temp = [];
      idData.map((it, idx)=>{
        let val = it.replace("DATA_TRANSFER_REQ_","");
        temp.push({value: val, label: val});
      })
      setLabelList(temp);
    }
  },[])

  const [dataTransferMessageId, setDataTransferMessageId] = useState();


  const putActionOfMessageList = async()=>{
    if(!dataTransferMessageId || !actionSelect)return;
    axios.put(`/controlhub/api/cpouser/v1/ActionSets/${data?.actionSetId}/DATA_TRANSFER_REQ`,{
      "dataTransferMessageId" : dataTransferMessageId,
      "controlHubActionInfoIds": [actionSelect?.controlHubActionId]
    },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      
    })
  }

  const [dataLabel, setDataLabel] = useState();

  return (
    <div className='cpo-system-setting-container'>

      <div className="cpo-system-setting-inner chas">
      <div className="cpo-chas-content-container">
        <div className='cpo-chas-content-header'>
          <div className='cpo-chas-data-container'><p>CHAS DATA</p><FaRegQuestionCircle onClick={()=>{setChasDataModalOn(!chasDataModalOn);}}/></div>
          {(chasDataModalOn)?
            <div className='cpo-chas-data-modal edit'>
              <p>{data?.description}</p>
              <p>{`${data?.createdDate} / ${data?.lastModifiedDate} / ${'1.0.0'} / ${'0.0.0'}`}</p>
            </div>
            :<></>
          }

          <div className='cpo-chas-title-container'>
            <p>{data?.name}</p>
          </div>
        </div>

        <div className="cpo-chas-edit-data-transfer-block-container">
          <p>Data Transfer Message Action Binding</p>
          <div className='cpo-chas-edit-data-transfer-wrapper'>
            <div className='cpo-chas-edit-data-transfer-container'>
              <p onClick={()=>{console.log(dataLabel);}}>데이터 식별명</p>
              <div className='cpo-chas-edit-data-transfer-input-container'>
                {/* <input type="text" placeholder='식별명을 입력하세요' value={dataTransferMessageId} onChange={(e)=>{setDataTransferMessageId(e.target.value)}}/> */}
                <SearchSelector placeholder="식별명을 입력 및 선택하세요" options={labelList} setDataLabel={setDataLabel} />
              </div>
            </div>
            <div className='cpo-chas-edit-data-transfer-container'>
              <p>액션</p>
              <div>
                { actionList && <Selector2 name={'name'} dataList={actionList} select={actionSelect} setSelect={setActionSelect} active={actionActive} setActive={setActionActive} activeReset={activeReset}/>}
              </div>
            </div>
            <div>
              <div className='cpo-chas-edit-message-select-button' onClick={()=>{putActionOfMessageList()}}>설정</div>
            </div>
          </div>
          
        </div>
        


        {/* 
        <div class="react-select__menu css-1nmdiq5-menu">
          <div class="react-select__menu-list css-qr46ko" role="listbox" aria-multiselectable="false" id="react-select-3-listbox">
            <div class="react-select__option react-select__option--is-focused css-d7l1ni-option" aria-disabled="false" id="react-select-3-option-0" tabindex="-1" role="option">chargingRate</div>
            <div class="react-select__option react-select__option--is-selected css-tr4s17-option" aria-disabled="false" id="react-select-3-option-1" tabindex="-1" role="option">test</div>
          </div>
        </div> */}


        
        </div>
      </div>
    </div>
  )
}
export default CpoChasEdit2;